import analyticsManifest from '../analytics/analyticsManifest';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import UIActions from '../actionCreators/UIActions';

const getFinalEvent = (event, data) => {
  if (isString(event)) {
      return {name: event}
  } else if (isFunction(event)) {
    return event(data)
  } else {
    return event
  }
}

const actionTracking = store => next => action => {
  const event = get(analyticsManifest, `actionEvents.${action.type}`, false);
  if (event) {
    const finalEvent = getFinalEvent(event, { action, state: store.getState() });
    store.dispatch(UIActions.enhancedTrack(finalEvent));
  }

  return next(action);
};


export default actionTracking;
