import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import reduce from 'lodash/reduce';
import mapValues from 'lodash/mapValues';
import ls from 'local-storage';
import { LOCATION_KEY, KITCHEN_KEY, LOCATION_SUBDOMAIN_COOKIE } from '../constants/sharedKeys';
import omitBy from 'lodash/omitBy';
import cookieUtils from '../utils/cookieUtils';
import { menuUrl } from '../utils/urlUtils';
import KitchenActions from '../actionCreators/KitchenActions';
import LocationActions from '../actionCreators/LocationActions';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

export const initialState = {
  delivery_address: {},
  kitchen: {},
  kitchens: [],
  pickupKitchens: [],
  error: null,
  loading: false,
  savedAddresses: {},
  scheduledSlots: {
    loading: false,
    list: []
  },
  placeId: null,
  dropoffId: null,
  currentPlace: null,
  selectedDropoff: null,
  pickup: false,
};

const storedLocation = ls.get(LOCATION_KEY) || {};
const storedKitchen = ls.get(KITCHEN_KEY) || {};

const fixKitchenEmptyHours = (kitchen) => {
  const hours = mapValues(kitchen.hours, (hours) => {
    if (hours.length === 1
      && hours[0].length === 2
      && hours[0][0] === "00:00"
      && hours[0][1] === "00:00") {
      return [];
    }
    return hours;
  })
  return { ...kitchen, hours };
}
const getDeliveryAddress = (data) => {
  // Due to a bug introduced in v327, location data may be stored in 2 different ways, so handle both here
  // into a standard way

  // If data *only* has location, we're in a bad state and punt
  if (data && Object.keys(data).length === 1 && data.location) {
    return {};
  }

  const { address, address_components, formattedAddress, location, type } = data;

  const components_obj = address && isObject(address) ? address : address_components;
  const formatted_string = formattedAddress || (address && isString(address) ? address : '')

  const addrCompsAsString = components_obj ?
    `${components_obj.address_1}, ${components_obj.city}, ${components_obj.state}, ${components_obj.zip_code}` : '';
  const addressString = isString(address_components) ? address_components : addrCompsAsString;
  const address_value = formatted_string ? formatted_string : addressString;

  return !isEmpty(data) ? omitBy({
    address_components: components_obj,
    address: address_value,
    location,
    type
  }, v => !v) : {};
};

const setLocation = (data, state, p = null, d = null, pickup = false) => {
  const cleanData = omitBy(data, v => !v);
  const delivery_address = getDeliveryAddress(cleanData);
  const places = get(state, 'places', null);
  const newLocation = get(delivery_address, 'location');
  const oldLocation = get(state, 'delivery_address.location');
  const isSameLocation = isEqual(newLocation, oldLocation);
  let placeId = get(state, 'placeId', null);
  let dropoffId = get(state, 'dropoffId', null);
  if (isSameLocation) {
    placeId = !isNil(p)? p : placeId;
    dropoffId = !isNil(d)? d : dropoffId;
  } else {
    placeId = p;
    dropoffId = d;
  }

  const currentPlace = find(places, ({id}) => id === placeId) || null;
  const dropoffLocations = get(currentPlace, 'dropoff_locations', []);
  const selectedDropoff = find(dropoffLocations, ({id}) => id === dropoffId) || null;

  const locationForStorage =  {
    ...(isSameLocation ? ls.get(LOCATION_KEY) : {}),
    ...delivery_address,
    placeId,
    dropoffId,
    pickup,
  };
  ls.set(LOCATION_KEY, locationForStorage);

  return {
    loading: false,
    error: null,
    delivery_address,
    placeId,
    dropoffId,
    currentPlace,
    selectedDropoff,
    pickup: pickup,
  };
};

const removeAddressType = (state) => {
  const { type: addrType, ...delivery_address } = get(state, 'delivery_address', {});
  return { loading: false, error: null, delivery_address  };
};

const setKitchen = (kitchen) => {
  ls.set(KITCHEN_KEY, kitchen);
  const fixedKitchen = fixKitchenEmptyHours(kitchen);
  return {
    loading: false,
    error: null,
    kitchen: fixedKitchen
  };
};

const removeLocation = (error) => {
  ls.remove(LOCATION_KEY);
  ls.remove(KITCHEN_KEY);
  return { ...initialState, error };
}



const requestHandler = (state) => ({ ...state, loading: true });
const errorHandler = (state, action) => {
  const error = get(action, 'payload.response.body');
  return { ...state, loading: false, error };
};

const orderUpdate = (state, action) => {
  const addressUpdate = get(action, 'payload.delivery_address');
  const placeId = get(action, 'payload.delivery_place_id');
  const dropoffId = get(action, 'payload.dropoff_location_id');
  const pickup = get(action, 'payload.pickup', false);

  return {
    ...state,
    ...setLocation(addressUpdate, state, placeId, dropoffId, pickup)
  }
};

export default createReducer(
  {
    ...initialState,
    kitchen: storedKitchen,
    delivery_address: getDeliveryAddress(storedLocation),
    error: null,
    placeId: get(storedLocation, 'placeId', null),
    dropoffId: get(storedLocation, 'dropoffId', null),
    pickup: get(storedLocation, 'pickup', false),
    places: []
  },
  {
    [ActionTypes.NEAREST_KITCHEN_REQUEST]: requestHandler,
    [ActionTypes.LOCATION_CHANGE_REQUEST]: requestHandler,
    [ActionTypes.KITCHENS_LOAD_REQUEST]: requestHandler,
    [ActionTypes.KITCHEN_LOAD_REQUEST]: requestHandler,
    [ActionTypes.SAVED_ADDRESSES_LOAD_REQUEST]: requestHandler,
    [ActionTypes.SAVED_ADDRESS_CREATE_REQUEST]: requestHandler,
    [ActionTypes.SAVED_ADDRESS_REMOVE_REQUEST]: requestHandler,

    [ActionTypes.KITCHENS_LOAD_ERROR]: errorHandler,
    [ActionTypes.KITCHEN_LOAD_ERROR]: errorHandler,
    [ActionTypes.SAVED_ADDRESSES_LOAD_ERROR]: errorHandler,
    [ActionTypes.SAVED_ADDRESS_CREATE_ERROR]: errorHandler,
    [ActionTypes.SAVED_ADDRESS_REMOVE_ERROR]: errorHandler,

    [ActionTypes.NEAREST_KITCHEN_SUCCESS]: (state, action) => {
      const kitchen =  get(action, 'payload.kitchen');
      const kitchen_in_zone =  get(action, 'payload.in_zone');
      const location = get(action, 'meta.data.location');
      const address = get(action, 'meta.data.address');
      const type = get(action, 'meta.data.type');
      const formattedAddress = get(action, 'meta.data.formatted_address');
      const isSameKitchen = get(kitchen, 'id') === get(state, 'kitchen.id');

      if (kitchen_in_zone && !isSameKitchen) {
        const subdomain = get(kitchen, 'subdomain');
        const deliveryAddress = {
          location,
          address: formattedAddress,
          address_components: address,
          type
        };
        ls.remove(LOCATION_KEY);
        window.location.replace(menuUrl(subdomain, deliveryAddress));
      } else {
        action.sideEffect((store) => {
            store.dispatch(LocationActions.pickupLocationsLoad(get(kitchen, 'location.lat'), get(kitchen, 'location.lng')));
        });
      }

      return {
        ...state,
        ...(kitchen_in_zone && isSameKitchen ?
          setLocation({ location, formattedAddress, address, type }, state, null, null, state.pickup) : {}),
      };
    },

    [ActionTypes.NEAREST_KITCHEN_ERROR]: (state, action) => {
      const error = get(action, 'payload.response.body');
      const reset = get(action, 'meta.data.reset');
      return {
        ...state,
        ...(reset ? removeLocation(error) : { error })
      };
    },

    [ActionTypes.LOCATION_CHANGE_SUCCESS]: (state, action) => {
      const kitchen =  get(action, 'payload');
      const location = get(action, 'meta.data.location');
      const address = get(action, 'meta.data.address');
      const formattedAddress = get(action, 'meta.data.formatted_address');
      const type = get(action, 'meta.data.type');
      return {
        ...state,
        ...setLocation({ location, formattedAddress, address, type }, state, null, null, state.pickup),
        ...setKitchen(kitchen)
      };
    },

    [ActionTypes.LOCATION_CHANGE_ERROR]: (state, action) => {
      const error = get(action, 'payload.response.body');

      return { ...state, ...removeLocation(error) };
    },

    [ActionTypes.SET_DELIVERY_ADDRESS]: (state, action) => {
        const payload =  get(action, 'payload', {});
        return { ...state, ...setLocation(payload, state) };
      },

    // Update stored delivery address if the user updates the order address
    [ActionTypes.ORDER_UPDATE_SUCCESS]: orderUpdate,
    [ActionTypes.ORDER_CREATE_OR_UPDATE_SUCCESS]: orderUpdate,
    [ActionTypes.ORDER_DELIVERY_LOCATION_UPDATE_SUCCESS]: orderUpdate,

    [ActionTypes.GROUPORDER_CREATE_SUCCESS]: (state, action) => {
      const addressUpdate = get(action, 'payload.delivery_address');
      const placeId = get(action, 'payload.delivery_place_id');
      const pickup = get(action, 'payload.pickup', false);
      return { ...state, ...setLocation(addressUpdate, state, placeId, null, pickup) }
    },

    [ActionTypes.START_GROUP_CHECKOUT]: (state, action) => {
      const addressUpdate = get(action, 'payload.delivery_address');
      const placeId = get(action, 'payload.delivery_place_id');
      const dropoffId = get(action, 'payload.dropoff_location_id');
      const pickup = get(action, 'payload.pickup', false);

      return {
        ...state,
        ...setLocation(
          addressUpdate,
          state,
          placeId,
          dropoffId,
          pickup,
        )
      }
    },

    [ActionTypes.KITCHENS_LOAD_SUCCESS]: (state, action) => {
      const kitchens =  get(action, 'payload', [])
        .filter(kitchen => kitchen.kitchen_state === 'online');
      return { ...state, loading: false, kitchens }
    },

    [ActionTypes.KITCHEN_LOAD_SUCCESS]: (state, action) => {
      const kitchen =  get(action, 'payload');
      const id = get(kitchen, 'id');
      const storedId = get(state, 'kitchen.id');
      const places = get(state, 'places');
      if (id && (id !== storedId || isEmpty(places))) {
        action.sideEffect((store) => {
            store.dispatch(KitchenActions.visiblePlacesLoad(id));
        });
      }

      action.sideEffect((store) => {
          store.dispatch(LocationActions.pickupLocationsLoad(get(kitchen, 'location.lat'), get(kitchen, 'location.lng')));
      });

      return { ...state, ...setKitchen(kitchen) };
    },

    [ActionTypes.SAVED_ADDRESSES_LOAD_SUCCESS]: (state, action) => {
      const work =  get(action, 'payload.work');
      const home =  get(action, 'payload.home');

      return { ...state, savedAddresses: { work, home }, loading: false, error: null };
    },

    [ActionTypes.SAVED_ADDRESS_CREATE_SUCCESS]: (state, action) => {
      const payload =  get(action, 'payload');
      const savedAddresses = { ...state.savedAddresses, [payload.address_type]: payload };
      return { ...state, savedAddresses, loading: false, error: null };
    },

    [ActionTypes.SAVED_ADDRESS_REMOVE_SUCCESS]: (state, action) => {
      const meta =  get(action, 'meta.data.id');
      const addressType =  get(action, 'payload.address_type');
      const storedLocation = ls.get(LOCATION_KEY) || {};
      const storedAddressType =  get(storedLocation, 'type');
      const newState = (addressType === storedAddressType) ?
        { ...state, ...removeAddressType(state) } : state;

      const savedAddresses = reduce(
        state.savedAddresses,
        (sum, val, key) => {
          const newVal = get(val, 'id') !== meta ? val : null;
          return { ...sum, [key]: newVal };
        },
        {}
      );

      return { ...newState, savedAddresses, loading: false, error: null };
    },

    [ActionTypes.STORE_LOCATION_SUBDOMAIN]: (state, action) => {
      const subdomain = window.location.host.split('.')[0];
      if (subdomain !== 'www') {
        cookieUtils.setCookie(LOCATION_SUBDOMAIN_COOKIE, subdomain);
      }
      return {...state};
    },

    [ActionTypes.SCHEDULED_SLOTS_LOAD_REQUEST]: (state, action) => ({
      ...state, scheduledSlots: { ...state.scheduledSlots, loading: true }
    }),

    [ActionTypes.SCHEDULED_SLOTS_LOAD_SUCCESS]: (state, action) => {
      const payloadData =  get(action, 'payload.data', []);

      return { ...state, scheduledSlots: { list: payloadData, loading: false } };
    },

    [ActionTypes.SCHEDULED_SLOTS_LOAD_ERROR]: (state, action) => ({
      ...state, scheduledSlots: { ...state.scheduledSlots, loading: false }
    }),

    [ActionTypes.SCHEDULED_SLOTS_CLEAR]: (state, action) => ({
      ...state, scheduledSlots: { list: [], loading: false }
    }),

    [ActionTypes.VISIBLE_PLACES_LOAD_SUCCESS]: (state, action) => {
      const places =  get(action, 'payload.places');
      const placeId = get(state, 'placeId', null);
      const dropoffId = get(state, 'dropoffId', null);
      const currentPlace = find(places, ({id}) => id === placeId) || null;
      const dropoffLocations = get(currentPlace, 'dropoff_locations', []);
      const selectedDropoff = find(dropoffLocations, ({id}) => id === dropoffId) || null;

      return {
        ...state,
        places,
        ...(!isNil(placeId) ? {currentPlace, selectedDropoff} : {})
       };
    },

    [ActionTypes.PICKUP_LOCATIONS_LOAD_SUCCESS]: (state, action) => {
      // If it returns empty, force pickup state to false in case we've stored
      // true and then disabled locations
      const pickupKitchens = get(action, 'payload.pickup_locations')
      const pickupUpdate = isEmpty(pickupKitchens) ? {pickup: false} : {}

      return {
        ...state,
        ...pickupUpdate,
        pickupKitchens,
      }
    },

    [ActionTypes.SET_PICKUP]: (state, action) => {
      return {
        ...state,
        ...setLocation(state.delivery_address, state, state.placeId, state.dropoffId, action.payload, action.payload ? state.pickupLocationId : null)
      }
    },
  }
);
