import get from 'lodash/get';
import UserActions from '../actionCreators/UserActions';

export default ({ dispatch }) => {
  return (next) => (action) => {
    const status = get(action, 'payload.status')
    if (status === 401) {
      dispatch(UserActions.userLogout());
    }

    return next(action);
  };
};
