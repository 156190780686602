import { createActions } from 'redux-actions';
import ActionTypes from '../constants/UIActionTypes';

export default createActions(
  {},
  `${ActionTypes.SHOW_SIGNIN_SIGNUP_MODAL}`,
  `${ActionTypes.SET_SIGNIN_SIGNUP_MODAL_STATE}`,
  `${ActionTypes.SET_SIGNIN_SIGNUP_MODAL_MESSAGE}`,

  `${ActionTypes.PASSWORD_RESET_SUCCESS}`,
  `${ActionTypes.SHOW_ACCOUNT_CREATED}`,
  `${ActionTypes.TRACK}`,
  `${ActionTypes.ENHANCED_TRACK}`,
  `${ActionTypes.SET_TREATMENTS}`,
  `${ActionTypes.SET_TREATMENT_OVERRIDE}`,
  `${ActionTypes.SPLIT_READY}`,
  `${ActionTypes.SET_ANONYMOUS_ID}`,
  `${ActionTypes.SET_SESSION_ID}`,
  `${ActionTypes.SET_DISPLAY_BAG_FLYOUT}`,
  `${ActionTypes.SHOW_ITEM_DETAILS_MODAL}`,
  `${ActionTypes.SET_ITEM_DETAILS_STATE}`,
  `${ActionTypes.SET_ITEM_MODIFIERS}`,
  `${ActionTypes.SET_ITEM_SUGGESTIONS}`,
  `${ActionTypes.SHOW_SIDES_AND_DRINKS_MODAL}`,
  `${ActionTypes.SET_SIDES_AND_DRINKS_STATE}`,
  `${ActionTypes.SET_DISPLAY_HEADER_LOCATION_INPUT}`,
  `${ActionTypes.SHOW_GROUPORDER_WALKTHROUGH_MODAL}`,
  `${ActionTypes.SHOW_START_GROUPORDER_MODAL}`,
  `${ActionTypes.SHOW_INVITE_FRIENDS_MODAL}`,
  `${ActionTypes.SHOW_LEAVE_GROUP_MODAL}`,
  `${ActionTypes.SHOW_CANCEL_GROUP_MODAL}`,
  `${ActionTypes.SHOW_CHECKOUT_GROUP_MODAL}`,
  `${ActionTypes.SHOW_HELP_MODAL}`,
  `${ActionTypes.SHOW_INFO_MODAL}`,
  `${ActionTypes.SHOW_ONBOARDING_MODAL}`,
  `${ActionTypes.SET_DISPLAY_DROP_OFF_HELP_MODAL}`,
  `${ActionTypes.SET_LOCATION_INPUT_MODAL_STATE}`,
  `${ActionTypes.SET_LOCATION_MODAL_TRIGGERED}`,
  `${ActionTypes.SET_DISPLAY_FREE_DELIVERY_THRESHOLD_MODAL}`,
  `${ActionTypes.SHOW_NOTIFICATION_MODAL}`,
  `${ActionTypes.HIDE_NOTIFICATION_MODAL}`,
);
