// import ActionTypes from '../constants/ActionTypes';
import UIActionTypes from '../constants/UIActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import moment from 'moment';
import isString from 'lodash/isString';
import ls from 'local-storage';
import { KITCHEN_KEY, LOCATION_KEY, ORDER_KEY } from '../constants/sharedKeys';

const storedKitchen = ls.get(KITCHEN_KEY) || {};
const storedLoc = ls.get(LOCATION_KEY) || {};
const storedOrder = ls.get(ORDER_KEY);

const getLocalStorageAddress = (loc) => {
  const address = get(loc, 'address');
  return (isString(address) ? loc : null);
};

const getPickupAddress = (kitchen) => (
  get(kitchen, 'pickup_details', {})
);

const getOrderAddress = (order) => (
  get(order, 'delivery_address', {})
);

const validateScheduledFor = (scheduledFor) => {
  const sMoment = scheduledFor ?
    moment(scheduledFor) :
    undefined;

  return (
      sMoment &&
      sMoment.isAfter(moment().subtract(15, 'minutes'))
    ) ? scheduledFor : null;

}

const getOrderData = (order) => {
  const deliveryInstructions = get(order, 'delivery_instructions') || '';
  const scheduledFor = validateScheduledFor(
    get(order, 'scheduled_for')
  );
  return {
    deliveryInstructions,
    scheduledFor
  };
}

const getDeliveryPref = (order) => ({
  deliveryPref: get(order, 'deliverypref')
});


const getDeliveryAddress = (
  localStorageAddress,
  orderAddress,
  pickupAddress
) => ({
  ...localStorageAddress,
  ...orderAddress,
  ...pickupAddress
});

const localStorageAddress = getLocalStorageAddress(storedLoc);
const pickupAddress = getPickupAddress(storedKitchen);
const orderAddress = getOrderAddress(storedOrder);
const deliveryAddress = getDeliveryAddress(
  localStorageAddress,
  orderAddress,
  pickupAddress
);


export default createReducer(
  {
    deliveryAddress,
    localStorageAddress,
    orderAddress,
    pickupAddress,
    pickup: false,
    addressManagerState: {},
    deliveryTime: null,
    deliveryDate: null,
    ...getOrderData(storedOrder),
    ...getDeliveryPref(storedOrder),
    deliveryError: null
  },
  {

    [UIActionTypes.SET_PAYMENT_UI_DATA]: (state, action) => {
      const order = get(action, 'payload', {});
      const orderAddress = getOrderAddress(order);

      const localStorageAddress = getLocalStorageAddress(
        get(state, 'localStorageAddress', {})
      );
      const pickupAddress = getPickupAddress(
        get(state, 'pickupAddress', {})
      );
      const deliveryAddress = getDeliveryAddress(
        localStorageAddress,
        orderAddress,
        pickupAddress
      );

      return {
        ...state,
        deliveryAddress,
        orderAddress,
        ...getOrderData(order)
      };
    },

    [UIActionTypes.REMOVE_PAYMENT_UI_DATA]:
      (state) => ({
        ...state,
        deliveryAddress: null,
        orderAddress: null,
        pickupAddress: null,
        pickup: false,
        deliveryInstructions: '',
        scheduledFor: null,
        deliveryPref: null,
        deliveryTime: null,
        deliveryDate: null,
        addressManagerState: {},
        deliveryError: null

      }),

    [UIActionTypes.SET_ADDRESS_MANAGER_STATE]:
      (state, action) => ({ ...state, addressManagerState: action.payload }),

    [UIActionTypes.SET_PICKUP]:
      (state, action) => ({ ...state, pickup: action.payload }),

    [UIActionTypes.SET_DELIVERY_INSTRUCTIONS]:
      (state, action) => ({ ...state, deliveryInstructions: action.payload }),

    [UIActionTypes.SET_SCHEDULED_FOR]:
      (state, action) => ({ ...state, scheduledFor: action.payload }),

    [UIActionTypes.SET_UI_DELIVERY_ADDRESS]:
      (state, action) => ({ ...state, deliveryAddress: action.payload }),

    [UIActionTypes.SET_UI_DELIVERY_PREF]:
      (state, action) => ({ ...state, deliveryPref: action.payload }),

    [UIActionTypes.SET_UI_DELIVERY_TIME]:
      (state, action) => ({ ...state, deliveryTime: action.payload }),

    [UIActionTypes.SET_UI_DELIVERY_DATE]:
      (state, action) => ({ ...state, deliveryDate: action.payload }),

    [UIActionTypes.SET_UI_DELIVERY_ERROR]:
      (state, action) => ({ ...state, deliveryError: action.payload }),

  }
);
