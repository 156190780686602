import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';

export default createReducer(
  { 
    referralCode: {},
    loading: false,
    errors: null, 
    loaded: false
  },
  {
    [ActionTypes.REFERRAL_CODE_LOAD_REQUEST]: (state) => 
      ({ ...state, loading: true }),

    [ActionTypes.REFERRAL_CODE_LOAD_SUCCESS]: (state, action) => {
      const referralCode =  get(action, 'payload');
      return { ...state, referralCode, loading: false, loaded: true }
    },

    [ActionTypes.REFERRAL_CODE_LOAD_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.error');
      return { ...state, loading: false, errors, loaded: true };
    }
  }
);
