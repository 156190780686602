import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import ls from 'local-storage';
import OrderActions from '../actionCreators/OrderActions';
import isNil from 'lodash/isNil';
import UIActionTypes from '../constants/UIActionTypes';
import { USER_KEY } from '../constants/sharedKeys';
import filter from 'lodash/filter';

const GROUPORDER_KEY = 'group.order';
const current = ls.get(GROUPORDER_KEY);

const initialState = {
  in_progress: [],
  completed: [],
  groupOrders: {},
  currentGroup: current || {},
  errors: null,
  loading: false,
  userLeftGroups: [],
  isOwnerPaying: get(current, 'group_owner_paying', false),
  isGroupOwner: false,
  userId: get(ls.get(USER_KEY), 'id')
};


const onAuthSuccess = (state, action) => {
  const userId = get(action, 'payload.user.id');
  const currentGroupUserId = get(state, 'currentGroup.user.id');
  const isGroupOwner = currentGroupUserId ? currentGroupUserId === userId : false;
  return { ...state, userId, isGroupOwner };
};

const onSignUpSuccess = (state, action) => {
  const userId = get(action, 'payload.id');
  const currentGroupUserId = get(state, 'currentGroup.user.id');
  const isGroupOwner = currentGroupUserId ? currentGroupUserId === userId : false;
  return { ...state, userId, isGroupOwner };
};

const clearUser = (state) => {
  return { ...state, userId: undefined, isGroupOwner: false };
};

const setOrder = (order, groupOrders) => {
  return order.group_order ? {
    ...groupOrders,
    [order.id]: order,
    [order.slug]: order
  } : groupOrders;
};

const setCurrentGroup = (action, state) => {
  const order = get(action, 'payload');
  const currentGroup = get(state, 'currentGroup');
  const id = get(order, 'id');
  const userLeftGroups = get(state, 'userLeftGroups');
  const userLeftThisGroup = userLeftGroups.indexOf(id) >= 0;

  if(userLeftThisGroup) {
    return { currentGroup };
  }

  const isOwnerPaying = get(order, 'group_owner_paying', false);
  const isGroupOwner = get(order, 'user.id') === get(state, 'userId');


  if(order && order.state === 'new') {
    ls.set(GROUPORDER_KEY, order);
    return { isOwnerPaying, isGroupOwner, currentGroup: order };
  } else {
    ls.remove(GROUPORDER_KEY);
    return { currentGroup };
  }
};

const setCurrentGroupFromSubOrder = (action, currentGroup) => {
  const subGroupId = get(action, 'payload.group_id')
  const currentGroupId = get(currentGroup, 'id');
  if (subGroupId && currentGroupId !== subGroupId) {
    action.sideEffect((store) => {
      store.dispatch(OrderActions.currentGroupUpdate(subGroupId));
    });
  }
};

const startLoading = (state, action) => ({ ...state, loading: true, errors: null });

const clearCurrentGroup = (state, action) => {
  ls.remove(GROUPORDER_KEY);
  return { ...state, currentGroup: {} };
}

const orderError = (state, action) => {
  const errors = get(action, 'payload.response.body.description') ||
    get(action, 'payload.response.body.error');
  return { ...state, loading: false, errors }
};


const orderUpdate = (state, action) => {
  const groupOrders = setOrder(action.payload, state.groupOrders);
  setCurrentGroupFromSubOrder(action, state.currentGroup);
  return { ...state, groupOrders };
};

const orderCancel = (state, action) => {
  const canceledId = get(action, 'payload.id');
  const currentId = get(state, 'currentGroup.id');
  const shouldCancel = canceledId && currentId && canceledId === currentId;
  return shouldCancel ? clearCurrentGroup(state, action) : { ...state };
};

let currentGroupChannel = null;

export default createReducer(
  initialState,
  {
    [ActionTypes.ORDER_CANCEL_SUCCESS]: orderCancel,
    [ActionTypes.GROUPORDER_CANCEL_SUCCESS]: orderCancel,

    [UIActionTypes.LISTEN_TO_CURRENT_GROUP_CHANNEL]:
      (state, action) => {
        const orderId = get(action, 'payload');
        action.sideEffect((store) => {
          const socket = window.getWebSocket();
          currentGroupChannel = socket.channel(`orders:${orderId}`);
          currentGroupChannel.join()
          .receive("ok", resp => {
            console.log("current group channel join", resp)
          })
          .receive("error", resp => {
            console.log("current group channel error", resp)
          })

          currentGroupChannel.on("order.update", resp => {
            store.dispatch(OrderActions.currentGroupUpdateEvent(resp));
          })
        });

      return { ...state };
    },

    [UIActionTypes.LEAVE_CURRENT_GROUP_CHANNEL]: (state) => {
      if (!isNil(currentGroupChannel)) {
        currentGroupChannel.leave();
      }
      return { ...state };
    },

    [UIActionTypes.CURRENT_GROUP_UPDATE_EVENT]: (state, action) => {
      const {
        currentGroup,
        groupOrders,
        userLeftGroups
      } = state;
      const payload =  get(action, 'payload.payload');
      const orderId = get(payload, 'id');
      const currentGroupId = get(currentGroup, 'id');

      const userLeftThisGroup = userLeftGroups.indexOf(currentGroupId) >= 0;

      const updatedCurrentGroup = (!userLeftThisGroup && orderId === currentGroupId) ?
        { ...currentGroup, ...payload } : currentGroup;


      const order = {...groupOrders[orderId], ...payload }
      const updatedGroupOrders = {
        ...groupOrders,
        [order.id]: order,
        [order.slug]: order
      }

      return {
        ...state,
        currentGroup: updatedCurrentGroup,
        groupOrders: updatedGroupOrders
      };
    },

    [ActionTypes.GROUPORDERS_LOAD_REQUEST]: startLoading,

    [ActionTypes.GROUPORDERS_LOAD_SUCCESS]: (state, action) => {
      const in_progress =  get(action, 'payload.in_progress', []);
      const completed =  get(action, 'payload.completed', []);
      return { ...state, in_progress, completed, loading: false };
    },

    [ActionTypes.GROUPORDERS_LOAD_ERROR]: orderError,

    [ActionTypes.GROUPORDER_LOAD_SUCCESS]: (state, action) => {
      const order = {...action.payload, group_order: true };
      const groupOrders = setOrder(order, state.groupOrders);
      return { ...state, groupOrders };
    },

    [ActionTypes.ORDER_UPDATE_SUCCESS]: orderUpdate,
    [ActionTypes.ORDER_CREATE_OR_UPDATE_SUCCESS]: orderUpdate,

    [ActionTypes.GROUPORDER_UPDATE_SUCCESS]: (state, action) => {
      const groupOrders = setOrder(action.payload, state.groupOrders);
      return { ...state, groupOrders };
    },

    [ActionTypes.GROUPORDER_UPDATE_ERROR]: orderError,

    [ActionTypes.ORDER_LOAD_SUCCESS]: (state, action) => {
      const groupOrders = setOrder(action.payload, state.groupOrders);
      setCurrentGroupFromSubOrder(action, state.currentGroup);
      return { ...state, groupOrders };
    },


    [ActionTypes.GROUPORDER_JOIN]: (state, action) => {
      const updates = setCurrentGroup(action, state);

      action.sideEffect((store) => {
        const storeState = store.getState();
        const isGroupOrder = get(storeState, 'orderReducer.order.group_order');
        const orderId = get(storeState, 'orderReducer.order.id');
        if (!isGroupOrder && orderId) {
          store.dispatch(OrderActions.orderUpdate({ group_id: action.payload.id }, orderId));
        }
      });

      return { ...state, ...updates };
    },

    [ActionTypes.CURRENT_GROUP_UPDATE_SUCCESS]: (state, action) => {
      const updates = setCurrentGroup(action, state);

      return { ...state, ...updates };
    },

    [ActionTypes.GROUPORDER_CREATE_REQUEST]: (state, action) => {
      action.sideEffect((store) => {
        const src = get(store.getState(), 'orderReducer.src');
        if(src) {
          store.dispatch(OrderActions.clearMarketingSource());
        }
      });
      return {...state, loading: true, errors: null};
    },
    [ActionTypes.GROUPORDER_CREATE_SUCCESS]: (state, action) => {
      const updates = setCurrentGroup(action, state);
      action.sideEffect((store) => {
        store.dispatch(OrderActions.groupordersLoad());
      });
      return { ...state, ...updates };
    },
    [ActionTypes.GROUPORDER_CREATE_ERROR]: orderError,

    [ActionTypes.START_GROUP_CHECKOUT]: clearCurrentGroup,
    [ActionTypes.GROUPORDER_LEAVE]: (state) => {
      const id = get(state, 'currentGroup.id');
      const groupsLeft = get(state, 'userLeftGroups', []);

      ls.remove(GROUPORDER_KEY);
      return {
        ...state,
        currentGroup: {},
        isOwnerPaying: false,
        isGroupOwner: false,
        userLeftGroups: id ? [ ...groupsLeft, id ] : groupsLeft
      };
    },
    [ActionTypes.ORDER_CONFIRM_SUCCESS]: clearCurrentGroup,
    [ActionTypes.USER_PHONE_AUTH_LOGIN_SUCCESS]: onAuthSuccess,
    [ActionTypes.USER_PHONE_AUTH_REGISTER_SUCCESS]: onSignUpSuccess,
    [ActionTypes.USER_LOGOUT]: clearUser,

    [ActionTypes.VALIDATE_LOCATION_REQUEST]: (state, action) => {
      return { ...state, errors: null };
    },

    [UIActionTypes.REMOVE_FROM_USER_LEFT_GROUP]: (state, action) => {
      const userLeftGroups = filter(
        get(state, 'userLeftGroups', []),
        (i) => i !== get(action, 'payload')
        );

      return { ...state, userLeftGroups };
    },
  }
);
