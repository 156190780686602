const defaultProps = {
  image: '/basic-seo-image-1.jpg',
  title:'ClusterTruck',
  description: 'ClusterTruck is a delivery-only restaurant with a menu inspired by the best street food in the nation. No matter what you crave, we make it all fresh in our own kitchen—just minutes before it’s in your hands.'
};

const defaultMenuProps = {
  ...defaultProps,
  url: 'https://www.clustertruck.com/menu',
  title:'Menu',
}

export default {
  'home': {
    ...defaultProps,
    url: 'https://www.clustertruck.com/'
  },
  'referral': {
    ...defaultProps,
    title:'25% off your first ClusterTruck Order!',
    description: 'I ordered ClusterTruck today and the food was AMAZING. Use my promo code on your order!',
    url: 'https://www.clustertruck.com/referral'
  },
  'menu': {
    ...defaultMenuProps
  },
  'downtown-indy-menu': {
    ...defaultMenuProps,
    url: 'https://downtown-indy.clustertruck.com/menu',
    title:'Downtown Indianapolis Menu',
  },
  'downtown-columbus-menu': {
    ...defaultMenuProps,
    url: 'https://downtown-columbus.clustertruck.com/menu',
    title:'Downtown Columbus Menu',
  },
  'kansas-city-menu': {
    ...defaultMenuProps,
    url: 'https://kansas-city.clustertruck.com/menu',
    title:'Kansas City Menu',
  },
  'broad-ripple-menu': {
    ...defaultMenuProps,
    url: 'https://broad-ripple.clustertruck.com/menu',
    title:'Broad Ripple Menu',
  },
  'carmel-menu': {
    ...defaultMenuProps,
    url: 'https://carmel.clustertruck.com/menu',
    title:'Carmel Menu',
  },
};
