import { combineReducers } from 'redux';
import userReducer from './userReducer';
import uiReducer from './uiReducer';
import kitchenStatusReducer from './kitchenStatusReducer';
import {createResponsiveStateReducer} from 'redux-responsive'
import orderHistoryReducer from './orderHistoryReducer';
import groupOrderReducer from './groupOrderReducer';
import confirmedOrderReducer from './confirmedOrderReducer';
import orderReducer from './orderReducer';
import favoritesReducer from './favoritesReducer';
import referralCodeReducer from './referralCodeReducer';
import itemReducer from './itemReducer';
import locationReducer from './locationReducer';
import teamReducer from './teamReducer';
import treatmentReducer from './treatmentReducer';
import analyticsReducer from './analyticsReducer';
import menuReducer from './menuReducer';
import bagSuggestionsReducer from './bagSuggestionsReducer';
import itemModifiersReducer from './itemModifiersReducer';
import { connectRouter } from 'connected-react-router';
import marketingReducer from './marketingReducer';
import paymentUIReducer from './paymentUIReducer';
import subscriptionReducer from './subscriptionReducer';
import deliveryUIReducer from './deliveryUIReducer';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  userReducer,
  uiReducer,
  kitchenStatusReducer,
  orderHistoryReducer,
  groupOrderReducer,
  confirmedOrderReducer,
  orderReducer,
  favoritesReducer,
  referralCodeReducer,
  itemReducer,
  locationReducer,
  browser: createResponsiveStateReducer({
    extraSmall: 480,
    small: 768,
    medium: 992,
    large: 1200,
    menuMobile: 600
  }),
  teamReducer,
  treatmentReducer,
  analyticsReducer,
  menuReducer,
  bagSuggestionsReducer,
  itemModifiersReducer,
  marketingReducer,
  paymentUIReducer,
  subscriptionReducer,
  deliveryUIReducer,
});

export default rootReducer;
