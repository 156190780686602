import { SplitFactory } from '@splitsoftware/splitio';
import TREATMENTS from  '../constants/Treatments';
import UIActions from '../actionCreators/UIActions';
import isEmpty from 'lodash/isEmpty';

class Split {
  constructor() {
    this.factory = null;
    this.client = null;
  }

  start({store, anonymousId, traits = {}}) {
    this.stop()
      .then(() => {
        const config = {
          core: {
            authorizationKey: window.ENV.SPLIT_KEY,
            key: anonymousId
          },
          scheduler: {
            impressionsRefreshRate: 10
          }
        };
        this.factory = SplitFactory(config);
        this.client = this.factory.client();

        this.client.on(this.client.Event.SDK_READY, () => {
          store.dispatch(UIActions.splitReady());
          store.dispatch(
            UIActions.setTreatments(
              isEmpty(TREATMENTS) ? {} : this.client.getTreatments(TREATMENTS, traits)
            )
          );
        });

        this.client.on(this.client.Event.SDK_UPDATE, () => {
          store.dispatch(
            UIActions.setTreatments(
              isEmpty(TREATMENTS) ? {} : this.client.getTreatments(TREATMENTS, traits)
            )
          );
        });
      })
      .catch((err) => console.log(err));
  }

  stop() {
    return new Promise((resolve, reject) => {
      if (this.client !== null) {
        this.client.destroy()
          .then(() => {
            this.client = null;
            this.factory = null;
            resolve();
          })
          .catch((err) => reject(err));
      } else {
        resolve();
      }
    });
  }
};

export default new Split();