export function menuUrl(subdomain, deliveryAddress = {}, pickup, group) {
  let q = ''
  if (deliveryAddress.address) {
    const location = JSON.stringify(deliveryAddress.location);
    const components = deliveryAddress.address_components ? JSON.stringify(deliveryAddress.address_components) : '';
    q = `addr=${deliveryAddress.address}` +
      `&loc=${location}` +
      `&components=${components}` +
      `${deliveryAddress.type ? `&type=${deliveryAddress.type}` : ''}`
  } else if (pickup) {
    q = 'pickup=true'
  }
  if (group) {
    q += '&groupOrder=true'
  }

  return `//${subdomain}/menu?${q}`
}
