import React, { Component, lazy } from 'react';
import { Socket } from 'phoenix';
import { get } from 'lodash';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { createPromise } from 'redux-promise-middleware';
import rootReducer from './reducers/rootReducer';
import './App.sass'
import sideEffects from './middleware/sideEffects';
import actionTracking from './middleware/actionTracking';
import { Router, Route, Switch } from 'react-router-dom';
import {responsiveStoreEnhancer} from 'redux-responsive';
import LazyLoadWrapper from './utils/LazyLoadWrapper';
import unauthInterceptor from './middleware/unauthInterceptor';
import sessionIdInterceptor from './middleware/sessionIdInterceptor';
import UIActions from './actionCreators/UIActions';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import SplitSupport from './analytics/SplitSupport';
import MarketingTracking from './analytics/MarketingTracking';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SEOWrapper from "./SEO/SEOWrapper";
import { HelmetProvider } from 'react-helmet-async';

const HomePage = lazy(() => (
  import(
    './components/HomePage'
    /* webpackChunkName: "homepage" */
  )
));

const ReferralPage = lazy(() => (
  import(
    './components/ReferralPage'
    /* webpackChunkName: "referral" */
  )
));

const MenuPage = lazy(() => (
  import(
    './components/MenuPage'
    /* webpackChunkName: "menu" */
  )
));
const AccountIndex = lazy(() => (
  import(
    './components/AccountPage/Index'
    /* webpackChunkName: "account" */
  )
));
const Checkout = lazy(() => (
  import(
    './components/Checkout'
    /* webpackChunkName: "checkout" */
  )
));
const NotFoundPage = lazy(() => (
  import(
    './components/NotFoundPage'
    /* webpackChunkName: "notfound" */
  )
));
const OrderConfirmationPage = lazy(() => (
  import(
    './components/OrderConfirmationPage'
    /* webpackChunkName: "orderconfirmation" */
  )
));
const OrderIssuePage = lazy(() => (
  import(
    './components/OrderIssuePage'
    /* webpackChunkName: "orderissue" */
  )
));
const OrderEditPage = lazy(() => (
  import(
    './components/OrderEditPage'
    /* webpackChunkName: "orderedit" */
  )
));
const OrderRatingPage = lazy(() => (
  import(
    './components/OrderRatingPage'
    /* webpackChunkName: "orderrating" */
  )
));
const Profile = lazy(() => (
  import(
    './components/AccountPage/Profile'
    /* webpackChunkName: "profile" */
  )
));
const SlackAuthPage = lazy(() => (
  import(
    './components/SlackAuthPage'
    /* webpackChunkName: "slackauth" */
  )
));
const PaymentMethodsPage = lazy(() => (
  import(
    './components/AccountPage/PaymentMethods'
    /* webpackChunkName: "paymentmethods" */
  )
));
const FoodPreferencesPage = lazy(() => (
  import(
    './components/AccountPage/FoodPreferencesPage'
    /* webpackChunkName: "foodprefs" */
  )
));
const RecentPage = lazy(() => (
  import(
    './components/RecentPage'
    /* webpackChunkName: "recent" */
  )
));
const ListGroupsPage = lazy(() => (
  import(
    './components/ListGroupsPage'
    /* webpackChunkName: "listgroups" */
  )
));
const NewGroupPage = lazy(() => (
  import(
    './components/NewGroupPage'
    /* webpackChunkName: "newgroup" */
  )
));
const EditGroupPage = lazy(() => (
  import(
    './components/EditGroupPage'
    /* webpackChunkName: "editgroup" */
  )
));
const GroupInfoPage = lazy(() => (
  import(
    './components/GroupInfoPage'
    /* webpackChunkName: "groupinfo" */
  )
));
const ClusterPoints = lazy(() => (
  import(
    './components/AccountPage/ClusterPoints'
    /* webpackChunkName: "clusterpoints" */
  )
));

const SavedAddresses = lazy(() => (
  import(
    './components/AccountPage/SavedAddresses'
    /* webpackChunkName: "savedaddresses" */
  )
));

const BigCookieSignUp = lazy(() => (
  import(
    './components/BigCookieSignUp'
    /* webpackChunkName: "bigcookiesignup" */
  )
));

const BigCookieClub = lazy(() => (
  import(
    './components/AccountPage/BigCookieClub'
    /* webpackChunkName: "bigcookieclub" */
  )
));

const devToolsExtention = (window.ENV.CT_ENV !== 'production' &&
  typeof window !== 'undefined' &&
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined') ?
  window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;

const history = createBrowserHistory();

const store = createStore(
  rootReducer(history),
  compose(
    responsiveStoreEnhancer,
    applyMiddleware(
      unauthInterceptor,
      sessionIdInterceptor,
      createPromise({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'ERROR']
      }),
      sideEffects,
      actionTracking,
      routerMiddleware(history)
    ),
    devToolsExtention
  )
);

if (typeof window !== 'undefined') {
  window.getWebSocket = () => {
    if (!window.webSocket) {
      window.webSocket = new Socket('/socket', {});
    }

    window.webSocket.connect();
    return window.webSocket;
  };
}

const HomePageWrapper = (props) => (
  <SEOWrapper
    page='home'
    children={<HomePage {...props} />}/>
);

const MenuPageWrapper = (props) => {
  const subdomain = typeof window !== 'undefined' ? window.location.hostname.split('.')[0] : '';
  const notAKitchen =  ['localhost', 'www', 'dev', 'staging' ].indexOf(subdomain) >= 0;
  return (
    <SEOWrapper
      page={`${notAKitchen ? 'menu' : `${subdomain}-menu`}`}
      children={<MenuPage {...props} />}/>
  );
};

const PrerenderMenuWrapper = (props) => {
  const { match: { params: { locationId = 'downtown-indy' } } } = props;
  return (
    <SEOWrapper
      page={`${locationId}-menu`}
      children={<MenuPage {...props} />}/>
  );
};

const AppRoutes = () => (
  <Switch>
    <Route exact path="/" component={HomePageWrapper} />
    <Route exact path="/locations" component={HomePageWrapper} />
    <Route exact path="/locations/:locationId" component={HomePageWrapper} />
    <Route path="/fb" component={HomePageWrapper} />
    <Route exact path="/menu" component={MenuPageWrapper} />
    <Route exact path="/menu." component={MenuPageWrapper} />
    <Route exact path="/menu/:locationId" component={PrerenderMenuWrapper} />
    <Route exact path="/t/:groupId" component={MenuPageWrapper} />
    <Route exact path="/bag" component={MenuPageWrapper} />
    <Route exact path="/recent" component={RecentPage} />
    <Route exact path="/recovery/:id" component={MenuPageWrapper} />
    <Route exact path="/me" component={AccountIndex} />
    <Route exact path="/me/profile" component={Profile} />
    <Route exact path="/me/cards" component={PaymentMethodsPage} />
    <Route exact path="/me/payment-methods" component={PaymentMethodsPage} />
    <Route exact path="/me/address" component={SavedAddresses} />
    <Route exact path="/me/food-preferences" component={FoodPreferencesPage} />
    <Route exact path="/me/points" component={ClusterPoints} />
    <Route exact path="/me/big-cookie-club" component={BigCookieClub} />
    <Route exact path="/me/auth/slack" component={SlackAuthPage} />
    <Route path="/checkout" component={Checkout} />
    <Route exact path="/orders/:orderNumber" component={OrderConfirmationPage} />
    <Route exact path="/orders/:orderNumber/edit" component={OrderEditPage} />
    <Route exact path="/orders/:orderId/issue" component={OrderIssuePage} />
    <Route exact path="/orders/:orderId/rating" component={OrderRatingPage} />
    <Route exact path="/trucks/:truckId/:itemId" component={MenuPageWrapper} />
    <Route exact path="/addons/:itemId" component={MenuPageWrapper} />
    <Route exact path="/groups" component={ListGroupsPage} />
    <Route exact path="/groups/new" component={NewGroupPage} />
    <Route exact path="/groups/:groupId" component={GroupInfoPage} />
    <Route exact path="/groups/edit/:groupId" component={EditGroupPage} />
    <Route exact path="/g/:groupId" component={GroupInfoPage} />
    <Route exact path="/referral" render={(props) => (
      <SEOWrapper
        page='referral'
        children={<ReferralPage {...props} />}/>
    )} />
    <Route exact path="/big-cookie-club" component={BigCookieSignUp} />
    <Route path="*" component={NotFoundPage} />
  </Switch>
);

// const apiKey = window.Stripe ? window.ENV.STRIPE_PUB_KEY : null;
const apiKey = window.ENV.STRIPE_PUB_KEY;
const stripePromise = loadStripe(apiKey);

const StripeSupport = ({ apiKey, children }) => (
  apiKey ?
    <Elements stripe={stripePromise}>
      { children }
    </Elements> : children
);

history.listen((route, action) => {
  const scroll = get(route, 'state.scroll', true)
  if (scroll && (action === 'PUSH' || action === 'REPLACE')) {
    window.scrollTo(0, 0)
  }
})

class App extends Component {
  render() {
    return (
      <HelmetProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
           <SplitSupport>
             <MarketingTracking>
              <StripeSupport apiKey={apiKey}>
                <div className="App">
                  <LazyLoadWrapper>
                    <AppRoutes />
                  </LazyLoadWrapper>
                </div>
              </StripeSupport>
             </MarketingTracking>
            </SplitSupport>
          </ConnectedRouter>
        </Provider>
      </HelmetProvider>
    );
  }
}

export default () => (
  <Router history={history}>
    <Route component={App} path="/" />
  </Router>
)
