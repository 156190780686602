import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import UIActionTypes from '../constants/UIActionTypes';
import ConfirmedOrderActions from '../actionCreators/ConfirmedOrderActions';
import { formatErrors } from '../utils/errorUtils';
import isNil from 'lodash/isNil';

const initialState = {
  currentOrders: [],
  orders: {},
  order: undefined,
  errors: null,
  loading: false
};

const updateOrders = (order, orders) => {
  const orderNumber = order.number ? order.number : get(orders[order.id], 'number', order.id);
  const updatedOrder = { ...orders[order.id], ...order, number: orderNumber };
  return {
    order: updatedOrder,
    orders: {
      ...orders,
      [order.id]: updatedOrder,
      [orderNumber]: updatedOrder
    }
  };
};

let currentOrderChannel = null;

export default createReducer(
  initialState,
  {
    [UIActionTypes.LISTEN_TO_ORDER_CHANNEL]:
      (state, action) => {
        const orderId = get(action, 'payload');
        action.sideEffect((store) => {
          const socket = window.getWebSocket();
          const channel = socket.channel(`orders:${orderId}`);
          channel.join()
          .receive("ok", resp => {
            console.log("Joined order channel successfully", resp)
          }).receive("error", resp => { console.log("Unable to join order channel", resp) })

          channel.on("order.update", resp => {
            store.dispatch(ConfirmedOrderActions.orderUpdateEvent(resp));
          })
        });

      return { ...state, kitchenSlug: action.payload };
    },

    [UIActionTypes.JOIN_CURRENT_ORDER_CHANNEL]:
      (state, action) => {
        const userId = get(action, 'payload');
        if (userId) {
          action.sideEffect((store) => {
            const socket = window.getWebSocket();
            currentOrderChannel = socket.channel(`users:current_orders:${userId}`);
            currentOrderChannel.join()
            .receive("ok", resp => {
              if (resp.orders) {
                store.dispatch(ConfirmedOrderActions.updateCurrentOrder(resp));
              }
            }).receive("error", resp => { console.log("join current orders error", resp) });

            currentOrderChannel.on("update", resp => {
              store.dispatch(ConfirmedOrderActions.updateCurrentOrder(resp));
            });
          });
        }
      return { ...state };
    },

    [UIActionTypes.LEAVE_CURRENT_ORDER_CHANNEL]: (state) => {
      if (!isNil(currentOrderChannel)) {
        currentOrderChannel.leave();
      }
      return { ...state, currentOrders: [] };
    },

    [ActionTypes.CONFIRMED_ORDER_LOAD_REQUEST]: (state, action) => ({
      ...state, loading: true, errors: null
    }),
    [ActionTypes.CONFIRMED_ORDER_LOAD_SUCCESS]: (state, action) => {
      const order =  get(action, 'payload');
      const updates = updateOrders(order, state.orders);
      return { ...state, ...updates, loading: false, errors: null }
    },
    [ActionTypes.CONFIRMED_ORDER_LOAD_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.error');
      return { ...state, loading: false, errors };
    },

    [ActionTypes.CONFIRMED_ORDER_RATE_REQUEST]: (state, action) => ({
      ...state, loading: true, errors: null
    }),
    [ActionTypes.CONFIRMED_ORDER_RATE_SUCCESS]: (state, action) => {
      const order =  get(action, 'payload');
      const updates = updateOrders(order, state.orders);
      return { ...state, ...updates, loading: false, errors: null };
    },
    [ActionTypes.CONFIRMED_ORDER_RATE_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.error');
      return { ...state, loading: false, errors };
    },

    [ActionTypes.CONFIRMED_ORDER_UPDATE_REQUEST]: (state, action) => ({
      ...state, loading: true, errors: null
    }),
    [ActionTypes.CONFIRMED_ORDER_UPDATE_SUCCESS]: (state, action) => {
      const order =  get(action, 'payload');
      const updates = updateOrders(order, state.orders);
      return { ...state, ...updates, loading: false, errors: null };
    },
    [ActionTypes.CONFIRMED_ORDER_UPDATE_ERROR]: (state, action) => {
      const formattedError = formatErrors(get(action, 'payload.response.body'), {});
      const errors = formattedError.message;
      return { ...state, loading: false, errors };
    },

    [ActionTypes.ORDER_ISSUE_REQUEST]: (state, action) => ({
      ...state, loading: true, errors: null
    }),
    [ActionTypes.ORDER_ISSUE_SUCCESS]: (state, action) => {
      const order =  get(action, 'payload');
      const updates = updateOrders(order, state.orders);
      return { ...state, ...updates, loading: false, errors: null }
    },
    [ActionTypes.ORDER_ISSUE_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.error');
      return { ...state, loading: false, errors };
    },
    [ActionTypes.ORDER_UPDATE_EVENT]: (state, action) => {
      const last_update = (new Date()).getTime();
      const payload = get(action, 'payload.payload', {});
      const driverState = get(state.orders[payload.id], 'driver', {})
      const updates = updateOrders({ ...payload, driver: { ...driverState, ...payload.driver }, last_update }, state.orders);
      return { ...state, ...updates, loading: false, errors: null }
    },
    [UIActionTypes.UPDATE_CURRENT_ORDER]: (state, action) => {
      const currentOrders =  get(action, 'payload.orders');
      return { ...state, currentOrders}
    },
    [ActionTypes.USER_LOGOUT]: (state, action) => {
      action.sideEffect((store) => {
        store.dispatch(ConfirmedOrderActions.leaveCurrentOrderChannel());
      });
      return { ...state };
    },
  }
);
