import keyMirror from 'fbjs/lib/keyMirror';

export default keyMirror({
    SHOW_SIGNIN_SIGNUP_MODAL: null,
    SET_SIGNIN_SIGNUP_MODAL_STATE: null,
    SET_SIGNIN_SIGNUP_MODAL_MESSAGE: null,
    PASSWORD_RESET_SUCCESS: null,
    SHOW_ACCOUNT_CREATED: null,
    LISTEN_TO_KITCHEN_CHANNEL: null,
    UPDATE_WAIT_TIME_ESTIMATE: null,
    KITCHEN_ANNOUNCEMENT: null,
    KITCHEN_OPEN: null,
    LISTEN_TO_ORDER_CHANNEL: null,
    SET_DELIVERY_PREFERENCE: null,
    TRACK: null,
    ENHANCED_TRACK: null,
    SET_TREATMENTS: null,
    SET_TREATMENT_OVERRIDE: null,
    SPLIT_READY: null,
    SET_ANONYMOUS_ID: null,
    SET_SESSION_ID: null,
    SET_DISPLAY_BAG_FLYOUT: null,
    SHOW_ITEM_DETAILS_MODAL: null,
    SET_ITEM_DETAILS_STATE: null,
    SET_ITEM_MODIFIERS: null,
    SET_ITEM_SUGGESTIONS: null,
    SHOW_SIDES_AND_DRINKS_MODAL: null,
    SET_SIDES_AND_DRINKS_STATE: null,
    SET_DISPLAY_HEADER_LOCATION_INPUT: null,
    JOIN_CURRENT_ORDER_CHANNEL: null,
    LEAVE_CURRENT_ORDER_CHANNEL: null,
    UPDATE_CURRENT_ORDER: null,
    LISTEN_TO_CURRENT_GROUP_CHANNEL: null,
    LEAVE_CURRENT_GROUP_CHANNEL: null,
    CURRENT_GROUP_UPDATE_EVENT: null,
    SHOW_GROUPORDER_WALKTHROUGH_MODAL: null,
    SHOW_START_GROUPORDER_MODAL: null,
    SHOW_INVITE_FRIENDS_MODAL: null,
    SHOW_LEAVE_GROUP_MODAL: null,
    SHOW_CANCEL_GROUP_MODAL: null,
    SHOW_CHECKOUT_GROUP_MODAL: null,
    REMOVE_FROM_USER_LEFT_GROUP: null,
    SHOW_HELP_MODAL: null,
    SHOW_INFO_MODAL: null,
    SHOW_ONBOARDING_MODAL: null,
    SET_DISPLAY_DROP_OFF_HELP_MODAL: null,
    SET_LOCATION_INPUT_MODAL_STATE: null,
    SET_LOCATION_MODAL_TRIGGERED: null,
    SET_DISPLAY_FREE_DELIVERY_THRESHOLD_MODAL: null,
    SHOW_NOTIFICATION_MODAL: null,
    HIDE_NOTIFICATION_MODAL: null,
    UPDATE_TIP_AMOUNT: null,
    SET_PROMO_CODE: null,
    CLEAR_PROMO_CODE: null,
    SET_SAVED_CARDS: null,
    REMOVE_SAVED_CARDS: null,
    SET_PAYMENT_UI_DATA: null,
    REMOVE_PAYMENT_UI_DATA: null,
    SET_CARD_ERROR: null,
    SET_SELECTED_CARD: null,
    SET_CARD_MANAGER_MODE: null,
    SET_REMEMBER_CARD: null,
    SET_ACCOUNT_BALANCE_USED: null,
    SET_ADDRESS_MANAGER_STATE: null,
    SET_DELIVERY_INSTRUCTIONS: null,
    SET_SCHEDULED_FOR: null,
    SET_UI_DELIVERY_ADDRESS: null,
    SET_PICKUP: null,
    SET_UI_DELIVERY_PREF: null,
    SET_UI_DELIVERY_TIME: null,
    SET_UI_DELIVERY_DATE: null,
    SET_UI_DELIVERY_ERROR: null,
    SET_UI_FUNDS_LOCK: null,
    CLEAR_GIFT_CARD: null,
    STORE_REDEMPTION_QUERY_PARAM: null
})
