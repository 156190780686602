import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import FavoritesActions from '../actionCreators/FavoritesActions';
import ls from 'local-storage';

const FAVORITES_KEY = 'favorites';
let faves = null;
try {
  faves = ls.get(FAVORITES_KEY)
}
catch (ex) {}


export const initialState = {
  favorites: faves || {},
  favorite: {},
  errors: null,
  loading: false
};

const startLoading = (state, action) => ({ ...state, loading: true, errors: null });

const errorHandler = (state, action) => {
  const errors = get(action, 'payload');
  return { ...state, loading: false, errors };
};

export default createReducer(
  initialState,
  {
    [ActionTypes.FAVORITES_LOAD_REQUEST]: startLoading,
    [ActionTypes.FAVORITE_LOAD_REQUEST]: startLoading,
    [ActionTypes.FAVORITE_CREATE_REQUEST]: startLoading,
    [ActionTypes.FAVORITE_REMOVE_REQUEST]: startLoading,
    [ActionTypes.FAVORITE_UPDATE_REQUEST]: startLoading,


    [ActionTypes.FAVORITES_LOAD_ERROR]: errorHandler,
    [ActionTypes.FAVORITE_LOAD_ERROR]: errorHandler,
    [ActionTypes.FAVORITE_CREATE_ERROR]: errorHandler,
    [ActionTypes.FAVORITE_REMOVE_ERROR]: errorHandler,
    [ActionTypes.FAVORITE_UPDATE_ERROR]: errorHandler,


    [ActionTypes.FAVORITES_LOAD_SUCCESS]: (state, action) => {
      const favorites = reduce(
        get(action, 'payload.favorites'),
        (sum, fav) => {
          const id = get(fav, 'customer_favorite_id');
          const total_price = get(fav, 'total_price');
          const display_total_price = get(fav, 'display_total_price');
          const savedItem = get(state, `favorites[${id}]`);
          const item = get(fav, 'item')
          sum[id] = { ...savedItem, item, total_price, display_total_price };
          return sum;
        },
        {}
      );
      ls.set(FAVORITES_KEY, favorites);
      return { ...state, loading: false, errors: null, favorites };
    },

    [ActionTypes.FAVORITE_LOAD_SUCCESS]: (state, action) => {
      const favorite = get(action, 'payload');
      return { ...state, loading: false, errors: null, favorite };
    },

    [ActionTypes.FAVORITE_CREATE_SUCCESS]: (state, action) => {
      const favorite = get(action, 'payload');
      action.sideEffect((store) => {
        store.dispatch(FavoritesActions.favoritesLoad());
      });
      return { ...state, loading: false, errors: null, favorite };
    },

    [ActionTypes.FAVORITE_REMOVE_SUCCESS]: (state, action) => {
      action.sideEffect((store) => {
        store.dispatch(FavoritesActions.favoritesLoad());
      });
      return { ...state, loading: false, errors: null, favorite: {} };
    },

    [ActionTypes.FAVORITE_UPDATE_SUCCESS]: (state, action) => {
      const favorite = get(action, 'payload');
      return { ...state, loading: false, errors: null, favorite };
    },

    [ActionTypes.CLEAR_FAVORITE]: (state) => {
      return { ...state, favorite: {} };
    },

    [ActionTypes.USER_LOGOUT]: (state) => {
      ls.remove(FAVORITES_KEY);
      return { ...state, favorites: {}, favorite: {} };
    }

  }
);
