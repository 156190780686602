import React from 'react';
import { Helmet } from 'react-helmet-async';
import SEOManifest from "./SEOManifest";

const SEOWrapper = ({ page, children }) => {
  const manifest = SEOManifest[page] || SEOManifest.home;
  const { title, description, image, url } = manifest;

    return (
      <div>
        <Helmet>
          <meta name="description" content={description} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="restaurant.restaurant" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
          <meta property="og:site_name" content="ClusterTruck" />
          <meta property="article:publisher" content="https://www.facebook.com/clustertruck/" />
          <meta property="og:image" content={`https://www.clustertruck.com${image}`} />
          <meta property="og:image:secure_url" content={`https://www.clustertruck.com${image}`} />
          <meta property="fb:app_id" content={window.ENV.FACEBOOK_APP_ID} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@clustertruck" />
          <meta name="twitter:creator" content="@clustertruck " />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={`https://www.clustertruck.com${image}`} />
          <title>{title}</title>
        </Helmet>
        <img src={image} alt='' style={{ display: 'none' }}/>
        { children }
      </div>
    )
}

export default SEOWrapper
