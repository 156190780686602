import React, { Component } from 'react';
import PropTypes from 'prop-types'

class ErrorBox2 extends Component {

    static propTypes = {
        errorMessage: PropTypes.string.isRequired
    };

    render() {
        return (
            <div className="error-message">
                <p>{this.props.errorMessage}</p>
            </div>
        );
    }
}

export default ErrorBox2;
