import get from 'lodash/get';

export default {
  customEvents: {
    onboardingExited: (index, skipped) => ({
      name: 'Onboarding Exited',
      properties: { index, skipped }
    }),
    cartViewed: {
      name: 'Cart Viewed',
      properties: {
        cart_id: '[[analyticsReducer.cart_id]]',
        products: '[[analyticsReducer.order.products]]'
      }
    },
    productListViewed: {
      name: 'Product List Viewed',
      properties: {
        list_id: '[[analyticsReducer.list_id]]'
      }
    },
    productViewed: {
      name: 'Product Viewed',
      properties: {
        product_id: '[[analyticsReducer.item.product_id]]',
        name: '[[analyticsReducer.item.name]]',
        category: '[[analyticsReducer.item.category]]',
        price: '[[analyticsReducer.item.price]]'
      }
    },
    checkoutStarted: (kitchenOpen) => ({
      name: 'Checkout Started',
      properties: {
        kitchenOpen,
        cart_id: '[[analyticsReducer.cart_id]]'
      }
    }),
    checkoutStepViewed: (step) => ({
      name: 'Checkout Step Viewed',
      properties: {
        cart_id: '[[analyticsReducer.cart_id]]',
        payment_method: '[[analyticsReducer.order.payment_method]]',
        step
      }
    }),
    checkoutStepCompleted: (step) => ({
      name: 'Checkout Step Completed',
      properties: {
        cart_id: '[[analyticsReducer.cart_id]]',
        payment_method: '[[analyticsReducer.order.payment_method]]',
        step: step - 1
      }
    }),
    orderUpdated: {
      name: 'Order Updated',
      properties: {
        cart_id: '[[analyticsReducer.cart_id]]',
        products: '[[analyticsReducer.order.products]]',
        revenue: '[[analyticsReducer.order.revenue]]',
        tax: '[[analyticsReducer.order.tax]]'
      }
    },
    orderCompleted: (properties) => ({
      name: 'Order Completed',
      properties
    }),
    groupOrderCompleted: (properties) => ({
      name: 'Group Order Completed',
      properties
    }),
    paymentInfoEntered: {
      name: 'Payment Info Entered',
      properties: {
        checkout_id: '[[analyticsReducer.cart_id]]',
        order_id: '[[analyticsReducer.cart_id]]',
        payment_method: '[[analyticsReducer.order.payment_method]]',
      }
    },
    productListFiltered: (filters) => ({
      name: 'Product List Filtered',
      properties: {
        list_id: '[[analyticsReducer.list_id]]',
        filters
      }
    }),
    productsSearched: (query) => ({
      name: 'Products Searched',
      properties: {
        query
      }
    }),
    tipAdjustmentClicked: (direction, value) => ({
      name: 'Tip Adjustment Clicked',
      properties: {
        direction,
        value
      }
    }),
    dynamicTipClicked: (position, display, value) => ({
      name: 'Dynamic Tip Changed',
      properties: {
        position,
        display,
        value
      }
    }),
    facebookButtonShown: {
      name: 'Facebook Share Button Shown',
      properties: {}
    },
    addressChangeSuccess: (page, addressType = null) => ({
      name: 'Address Change Success',
      properties: {
        page,
        addressType
      }
    }),
    addressChangeError: (page) => ({
      name: 'Address Change Error',
      properties: {
        page
      }
    }),
    addressChangeRedirect: (addressType) => ({
      name: 'Home Page Address Change Redirect',
      properties: {
        addressType
      }
    }),
    copyReferralLink: (page) => ({
      name: `${page ? `${page} ` : ''}Copy Referral Link Button Clicked`,
      properties: {}
    }),
    selectReferralLink: {
      name: 'Referral Link Text Selected',
      properties: {}
    },
    shareReferralLinkShown: {
      name: 'Share Referral Link CTA Shown',
      properties: {}
    },
    sidebarGroupOrderShown: {
      name: 'Sidebar Group Order Panel Shown',
      properties: {}
    },
    groupOrderStarted: {
      name: 'Group Order Started',
      properties: {}
    },
    groupOrderEdited: (properties) => ({
      name: 'Group Order Edited',
      properties
    }),
    socialShareClick: (type) => ({
      name: 'Social Sharing Button Clicked',
      properties: {
        type
      }
    }),
    redeemPointsSelected: (value) => ({
      name: 'Redeem Points Selection',
      properties: {
        optedIn: value
      }
    }),
    savedAddressCreated: (addressType) => ({
      name: 'Saved Address Created',
      properties: {
        addressType
      }
    }),
    suggestedItemSelected: (id) => ({
      name: 'Suggested Item Selected',
      properties: {
        id
      }
    }),
    modifierToggled: (id, value) => ({
      name: 'Item Modifier Toggled',
      properties: {
        id,
        value
      }
    }),
    favoriteItemPageView: {
      name: 'Favorite Item Page Viewed',
      properties: {}
    },
    addFavoriteButtonClicked: (removed) => ({
      name: 'Add To Favorites Button Clicked',
      properties: {
        removed
      }
    }),
    deliveryPreferenceSelected: (value) => ({
      name: 'Delivery Preference Selected',
      properties: {
        value
      }
    }),
    deliveryTimeSelected: (data) => {
      const date = get(data, 'delivery_date');
      const time = get(data, 'delivery_time')
      return {
        name: 'Delivery Time Selected',
        properties: {
          date,
          time
        }
      }
    },
    deliveryInstructionsChange: {
      name: 'Delivery Instructions Changed',
      properties: {}
    },
    creditCardSelected: {
      name: 'Credit Card Selected',
      properties: {}
    },
    menuComponentsLoaded: (components_to_be_shown, components_not_to_be_shown) => ({
      name: 'Menu Components Loaded',
      properties: {
        components_to_be_shown,
        components_not_to_be_shown
      }
    }),
    clusterpointsMenuComponentClicked: {
      name: 'Clusterpoints Menu Component Clicked',
      properties: {}
    },
    redirectedToMenu: subdomain => ({
      name: 'Redirected To Menu',
      properties: { subdomain }
    }),
    openDeliveryFeeThresholdModal: {
      name: 'Delivery Fee Threshold Modal Opened'
    },
    openExtrasModal: category => ({
      name: 'Extras Modal Opened',
      properties: { category }
    }),
    extraItemQuantityClicked: ({source, order_id, item_id, item_name, item_category, direction}) => ({
      name: 'Extra Item Quantity Clicked',
      properties: {source, order_id, item_id, item_name, item_category, direction},
    }),
    extraItemAdded: ({order_id, item_id, item_name, item_category, quantity}) => ({
      name: 'Extra Item Added',
      properties: { order_id, item_id, item_name, item_category, quantity }
    }),
    pickupOrDeliveryToggled: (pickup) => ({
      name: 'Toggled Pickup/Delivery',
      properties: { pickup }
    }),
    pickupLocationSelected: (pickupLocationId) => ({
      name: 'Pickup Location Selected',
      properties: { pickupLocationId }
    }),
    crossKitchenPickupLocationSelected: (pickupLocationId, locationKitchenId, currentKitchenId) => ({
      name: 'Cross Kitchen Pickup Location Selected',
      properties: { pickupLocationId, locationKitchenId, currentKitchenId }
    }),
  },

  composedEvents: {
    'add-to-cart-button-*': {
      onClick: {
        name: 'Product Added',
        properties: {
          product_id: '[[analyticsReducer.item.product_id]]',
          name: '[[analyticsReducer.item.name]]',
          category: '[[analyticsReducer.item.category]]',
          price: '[[analyticsReducer.item.price]]',
          quantity: '[[analyticsReducer.item.quantity]]'
        }
      }
    },
    'menu-item-*': {
      onClick: {
        name: 'Product Clicked'
      }
    },
    'food-item-*': {
      onClick: {
        name: 'Product Clicked'
      }
    },
    'add-favorite-to-cart-*': {
      onClick: {
        name: 'Product Added'
      }
    },
    'add-food-item-to-cart-*': {
      onClick: {
        name: 'Product Added'
      }
    },
    'suggested-item-add-btn-*': {
      onClick: {
        name: 'Product Added'
      }
    },
    'remove-from-cart-*': {
      onClick: {
        name: 'Product Removed'
      }
    },
    'location-dropdown-kitchen-*': {
      onClick: {
        name: 'Location Dropdown Select'
      }
    },
    'order-rating-fb-share-button': {
      onClick: {
        name: 'Facebook share button clicked'
      }
    },
    'sidebar-group-order-panel-button': {
      onClick: {
        name: 'Sidebar Group Order Panel Button Clicked'
      }
    },
    'start-group-order-button': {
      onClick: {
        name: 'Start Group Order Button Clicked'
      }
    },
    'sign-out-link': {
      onClick: {
        name: 'User Signed Out'
      }
    },
    'remove-favorite-button-*': {
      onClick: {
        name: 'Remove Favorite Button Clicked'
      }
    },
    'remove-saved-address-*': {
      onClick: {
        name: 'Remove Saved Address Button Clicked'
      }
    },
    'save-address-btn-*': {
      onClick: {
        name: 'Add Saved Address Button Clicked'
      }
    },
    'save-address-checkbox': {
      onClick: {
        name: 'Saved Address Checkbox Clicked'
      }
    },
    'view-clusterpoints-modal-btn': {
      onClick: {
        name: 'Clusterpoints Modal Shown'
      }
    },
    'location-search-button': {
      onClick: {
        name: 'Homepage Location Search Button Clicked'
      }
    },
    'location-search-button-inactive': {
      onClick: {
        name: 'View Menu Button Clicked Without Successful Location Search'
      }
    },
    'add-new-card-btn': {
      onClick: {
        name: 'Add New Card Button Clicked'
      }
    },
    'toggle-promo-box-btn': {
      onClick: {
        name: 'Promo Code Input Shown'
      }
    },
    'apply-promo-code-btn': {
      onClick: {
        name: 'Apply Promo Code Button Clicked'
      }
    },
    'remove-promo-code-btn': {
      onClick: {
        name: 'Remove Promo Code Button Clicked'
      }
    },
    'view-referral-modal-btn': {
      onClick: {
        name: 'Referral Promo Modal Opened'
      }
    },
    'remove-card-btn-*': {
      onClick: {
        name: 'Remove Credit Card Button Clicked'
      }
    },
    'food-preference-checkbox-*': {
      onClick: {
        name: 'Food Preference Checkbox Toggled'
      }
    },
    'account-profile-save-btn': {
      onClick: {
        name: 'Edit Profile Save Button Clicked'
      }
    },
    'sidebar-help-number': {
      onClick: {
        name: 'Sidebar Help Number Link Clicked'
      }
    },
    'bag-quantity-change-link': {
      onClick: {
        name: 'Bag Quantity Change Opened'
      }
    },
    'slack_banner': {
      onClick: {
        name: 'Slack Sidebar Clicked'
      }
    },
    'gift_card_banner': {
      onClick: {
        name: 'Gift Cards Sidebar Clicked'
      }
    },
    'component-*': {
      onClick: {
        name: 'Component Clicked'
      }
    },
    'mobile-app-badge-*': {
      onClick: {
        name: 'Mobile App Badge Clicked'
      }
    },
    'search-suggestion-*': {
      onClick: {
        name: 'Search Suggestion Clicked'
      }
    },
    'add-to-slack-btn-*': {
      onClick: {
        name: 'Add To Slack Button Clicked'
      }
    },
    'slack-menu-component-*': {
      onClick: {
        name: 'Menu Component Slack Button Clicked'
      }
    },
    'expiring-points': {
      onClick: {
        name: 'Expiring Points Checkout Help Button Clicked'
      }
    },
    'clusterpoints-breakdown': {
      onClick: {
        name: 'Expiring Points Modal Breakdown Clicked'
      }
    },
  },

  actionEvents: {
    USER_UPDATE_VALIDATE_PHONE_REQUEST: 'CT Auth: Validate Phone Request',
    USER_UPDATE_VALIDATE_PHONE_ERROR: 'CT Auth: Validate Phone Failure',
    USER_UPDATE_VALIDATE_PHONE_SUCCESS: 'CT Auth: Validate Phone Success',
    USER_UPDATE_CHANGE_PHONE_SUCCESS: 'Phone Auth: Change Request',
    USER_UPDATE_CHANGE_PHONE_ERROR: 'Phone Auth: Change Failure',
    USER_UPDATE_VERIFY_PHONE_SUCCESS: 'Phone Auth: Change Verify Success',
    USER_UPDATE_VERIFY_PHONE_ERROR: 'Phone Auth: Change Verify Failure',
    USER_AUTH_REQUEST: 'CT Auth: Auth Request',
    USER_AUTH_SUCCESS: 'CT Auth: Auth Success',
    USER_AUTH_ERROR: 'CT Auth: Auth Failure',
    USER_SIGNUP_REQUEST: 'CT Auth: Signup Request',
    USER_SIGNUP_SUCCESS: 'CT Auth: Signup Success',
    USER_SIGNUP_ERROR: 'CT Auth: Signup Failure',
    USER_PHONE_AUTH_CODE_REQUEST: 'Phone Auth: Code Request',
    USER_PHONE_AUTH_LOGIN_SUCCESS: 'Phone Auth: Login Success',
    USER_PHONE_AUTH_LOGIN_ERROR: 'Phone Auth: Login Failure',
    USER_PHONE_AUTH_REGISTER_SUCCESS: 'Phone Auth: Registration Success',
    USER_PHONE_AUTH_REGISTER_ERROR: 'Phone Auth: Registration Failure',
    USER_PHONE_AUTH_RECOVERY_SUCCESS: 'Phone Auth: Recovery Success',
    USER_PHONE_AUTH_RECOVERY_ERROR: 'Phone Auth: Recovery Failure',
    USER_PHONE_AUTH_RECOVERY_VERIFY_SUCCESS: 'Phone Auth: Recovery Verify Success',
    USER_PHONE_AUTH_RECOVERY_VERIFY_ERROR: 'Phone Auth: Recovery Verify Failure',
    SHOW_SIGNIN_SIGNUP_MODAL: ({ action: { payload } }) => ({
      name: 'Sign Up/Sign In Modal Toggled',
      properties: { shown: payload }
    }),
    MENU_EXECUTE_SEARCH_SUCCESS: ({ action: { payload }, state: { menuReducer: { filter } } }) => ({
      name: 'Search Result',
      properties: {
        tags: get(filter, 'tags'),
        query: get(filter, 'text'),
        resultCount: get(payload, 'results', []).length
      }
    }),
  }
};
