export const USER_KEY = 'user';
export const FUNDS_LOCK_KEY = 'funds_lock'
export const SHOW_DEFAULT = 'SHOW_DEFAULT';
export const SHOW_SIGN_IN = 'SHOW_SIGN_IN';
export const SHOW_SIGN_UP = 'SHOW_SIGN_UP';
export const SHOW_VERIFY_PHONE = 'SHOW_VERIFY_PHONE';
export const SHOW_VERIFY_PHONE_V1 = 'SHOW_VERIFY_PHONE_V1';
export const SHOW_PHONE_SIGN_IN = 'SHOW_PHONE_SIGN_IN';
export const SHOW_PHONE_SIGN_IN_VERIFY = 'SHOW_PHONE_SIGN_IN_VERIFY';
export const SHOW_PHONE_SIGN_IN_CREATE_PROFILE = 'SHOW_PHONE_SIGN_IN_CREATE_PROFILE';
export const SHOW_PHONE_SIGN_IN_RECOVERY = 'SHOW_PHONE_SIGN_IN_RECOVERY';
export const SHOW_PHONE_SIGN_IN_RECOVERY_SENT = 'SHOW_PHONE_SIGN_IN_RECOVERY_SENT';
export const SHOW_PHONE_SIGN_IN_RECOVERY_MERGE = 'SHOW_PHONE_SIGN_IN_RECOVERY_MERGE';
export const SHOW_RESET_PASSWORD = 'SHOW_RESET_PASSWORD';
export const SHOW_FINISH_ACCOUNT = 'SHOW_FINISH_ACCOUNT';
export const SHOW_CHANGE_PHONE = 'SHOW_CHANGE_PHONE';
export const SHOW_CHANGE_PHONE_V1 = 'SHOW_CHANGE_PHONE_V1';
export const SHOW_ORDER_CHANGE_PHONE = 'SHOW_ORDER_CHANGE_PHONE';
export const MARKETING_SOURCE_KEY = '_msrc';
export const UTM_SOURCE_KEY = '_utmsrc';
export const ORDER_UTM_SOURCE_KEY = '_o_utmsrc';
export const USER_UTM_SOURCE_KEY = '_u_utmsrc';
export const FRIENDS_SECTION = 'FRIENDS_SECTION';
export const DELIVERY_SECTION = 'DELIVERY_SECTION';
export const PAYMENT_SECTION = 'PAYMENT_SECTION';
export const SUMMARY_SECTION = 'SUMMARY_SECTION';
export const LOCATION_KEY = 'location';
export const KITCHEN_KEY = 'kitchen';
export const ORDER_KEY = 'order';
export const MENU_COMPONENTS_KEY = 'menu_components';
export const LOCATION_SUBDOMAIN_COOKIE = '_ctsubdomain';
export const RECENT_SEARCHES_KEY = 'recent_searches';
export const TREATMENTS_KEY = 'split_treatments';
export const LOCATION_INPUT_ADDRESS = 'LOCATION_INPUT_ADDRESS';
export const LOCATION_INPUT_DROPOFF = 'LOCATION_INPUT_DROPOFF';
export const LOCATION_INPUT_CONFIRM = 'LOCATION_INPUT_CONFIRM';
export const MODE_SELECT = 'select';
export const MODE_NEW_CARD = 'new';
