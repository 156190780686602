import ActionTypes from '../constants/ActionTypes';
import UIActionTypes from '../constants/UIActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';

export const initialState = {
  item: null,
  itemsBySlug: {},
  itemsById: {},
  error: null,
  errors: {},
  loading: false
};

const updateItems = (components) => {
  const itemsBySlug = {};
  const itemsById = {};

  components.forEach(component => {
    if (component.type === 'TruckMenuComponent') {
      const truckItems = get(component, 'data.items', []);
      truckItems.forEach(item => {
        itemsBySlug[item.slug] = item
        itemsById[item.id] = item
      });
    }
  });

  return { itemsBySlug, itemsById };
};

export default createReducer(
  initialState,
  {
    [UIActionTypes.SET_ITEM_DETAILS_STATE]: (state, action) =>
      ({ ...state, item: get(state.itemsById, action.payload) }),

    [ActionTypes.ITEM_LOAD_REQUEST]: (state, action) =>
      ({ ...state, error: null, errors: { }, loading: true }),

    [ActionTypes.ITEM_LOAD_SUCCESS]: (state, action) => {
      const item =  get(action, 'payload');
      const itemsBySlug = { ...state.itemsBySlug, [item.slug]: item };
      const itemsById = { ...state.itemsById, [item.id]: item };
      return { ...state, itemsBySlug, itemsById, loading: false, item }
    },

    [ActionTypes.ITEM_LOAD_ERROR]: (state, action) => {
      const error = get(action, 'payload.response.body');
      return { ...state, loading: false, error };
    },

    [ActionTypes.MENU_LOAD_SUCCESS]: (state, action) => {
      const components =  get(action, 'payload.components', []);
      const updatedItems = updateItems(components, state);
      return { ...state, ...updatedItems };
    },

    [ActionTypes.SET_ITEM_MODIFIER_ERRORS]: (state, action) => {
      return { ...state, errors: { modifier_groups: action.payload }}
    },

    [ActionTypes.ORDER_CREATE_OR_UPDATE_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.details.order_line_item', { })
      return {
        ...state,
        errors: errors
      }
    }
  }
);
