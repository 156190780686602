import UIActions from '../actionCreators/UIActions';
import cookieUtils from '../utils/cookieUtils';
import once from 'lodash/once';

const setSessionId = once( (dispatch, ct_session_id) => {
  dispatch(UIActions.setSessionId(ct_session_id));
})

export default ({ dispatch }) => {
  return (next) => (action) => {
    const ct_session_id = cookieUtils.getCookie('_ct_session_id');
    if (ct_session_id) {
      setSessionId(dispatch, ct_session_id);
    }

    return next(action);
  };
};
