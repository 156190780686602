import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';

const identify = (user, props) => {
  if (typeof window !== 'undefined' && window._cio) {
    if (!isEmpty(user)) {
      const traits = omitBy({
        id: get(user, 'id'),
        email: get(user, 'email'),
        full_name: get(user, 'full_name'),
        ...props
      }, v => !v);

      window._cio.identify(traits)
    }
  }
}

const track = (name, props) => {
  if (name && typeof window !== 'undefined' && window._cio) {
    window._cio.track(name, props);
  }
}

const page = (pathname, props) => {
  if (pathname && typeof window !== 'undefined' && window._cio) {
    window._cio.page(pathname, props);
  }
}

export { track, identify, page };