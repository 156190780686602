import UIActionTypes from '../constants/UIActionTypes';
import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';
import KitchenStatusActions from '../actionCreators/KitchenStatusActions';
import ItemActions from '../actionCreators/ItemActions'
import get from 'lodash/get';
import { getFriendlyDisplayTime } from '../utils/timeUtils';

const getKitchenMessage = ({ open, opens_at=null }) =>
  (open ? null :
    `Our kitchen is closed${opens_at ? ` until ${getFriendlyDisplayTime(opens_at)}` : ''}. But don't let that stop you from scheduling ahead!`);

const getKitchenShortMessage = ({ open, opens_at=null }) =>
  (open ? null : `Schedule ahead${opens_at ? ` for ${getFriendlyDisplayTime(opens_at)}` : ''}.`);

const initialState = {
  kitchenSlug: undefined,
  waitTime: null,
  open: null,
  opens_at: null,
  announcement: null,
  shortForceScheduleMessage: null,
  forceScheduleMessage: null
};

export default createReducer(
  initialState,
  {
    [UIActionTypes.LISTEN_TO_KITCHEN_CHANNEL]:
      (state, action) => {
        action.sideEffect((store) => {
          const href = typeof window !== 'undefined' && get(window, 'location.href');
          if (href && href.indexOf('localhost') === -1) {
            const socket = window.getWebSocket();
            const channel = socket.channel(`kitchens:${action.payload}`);
            channel.join()
            .receive("ok", resp => {
              if (resp.wait_time_estimate) {
                store.dispatch(KitchenStatusActions.updateWaitTimeEstimate(resp.wait_time_estimate));
              }
            })
            .receive("error", resp => { console.log("Unable to join", resp) });

            channel.on("new_announcement", resp => {
              store.dispatch(KitchenStatusActions.kitchenAnnouncement(resp));
            })

            channel.on("new_open_status", resp => {
              store.dispatch(KitchenStatusActions.kitchenOpen(resp.open_status));
            })

            channel.on("wait_time_estimate", resp => {
              store.dispatch(KitchenStatusActions.updateWaitTimeEstimate(resp));
            })

            // live item updates from realtime socket
            channel.on("new_item", item => {
              store.dispatch(ItemActions.itemLiveUpdate(item))
            })
          }
        });

        return { ...state, kitchenSlug: action.payload };
      },

    [UIActionTypes.UPDATE_WAIT_TIME_ESTIMATE]:
      (state, action) => {
        const predicted = get(action, 'payload.predicted');
        const waitTime = predicted !== -1 ? action.payload : null;
        return { ...state, waitTime };
      },

    [UIActionTypes.KITCHEN_ANNOUNCEMENT]:
      (state, action) => ({ ...state, announcement: get(action, 'payload.announcement') }),

    [UIActionTypes.KITCHEN_OPEN]:
      (state, action) => {
        const open = get(action, 'payload.open');
        const opens_at = get(action, 'payload.opens_at');
        const announcement = get(state, 'announcement');
        const forceScheduleMessage = getKitchenMessage({ open, opens_at });
        const shortForceScheduleMessage = getKitchenShortMessage({ open, opens_at });
        return { ...state, open, forceScheduleMessage, shortForceScheduleMessage, announcement };
      },

    [ActionTypes.KITCHEN_STATUS_LOAD_SUCCESS]:
      (state, action) => {
        const open = get(action, 'payload.open');
        const opens_at = get(action, 'payload.opens_at');
        const announcement = get(action, 'payload.announcement');
        const forceScheduleMessage = getKitchenMessage({ open, opens_at });
        const shortForceScheduleMessage = getKitchenShortMessage({ open, opens_at });
        return { ...state, open, opens_at, forceScheduleMessage, shortForceScheduleMessage, announcement };
      },

      [UIActionTypes.KITCHEN_STATUS_LOAD_ERROR]:
      (state, action) => ({ ...state, open: null, announcement: null }),
  }
);
