import moment from 'moment'

const ONE_HOUR = 3600

export function getOffsetAsISO8601(offset) {
  let sign = offset > 0 ? '+' : '-';
  let hourOffset = Math.abs(offset/ONE_HOUR);
  if(hourOffset < 10) {
    hourOffset = `0${hourOffset}`;
  }
  let minOffet = offset % ONE_HOUR;
  if (minOffet < 10) {
    minOffet = `0${minOffet}`;
  }
  return `${sign}${hourOffset}:${minOffet}`;
}

export function getFriendlyDisplayTime(timeStr) {
  if (!timeStr) { 
    return ''
  }

  const now = moment()
  const m = moment(timeStr)

  const timeDisplay = m.minutes() === 0 ? m.format('ha') : m.format('h:mma')
  if (now.weekday() === m.weekday()) {
    return timeDisplay
  }
  else if (now.weekday() === m.weekday() - 1) {
    return `tomorrow at ${timeDisplay}`
  }
  else if (now.weekday() === m.weekday() + 1) {
    return `yesterday at ${timeDisplay}`
  }
  else {
    return `${m.format('dddd')} at ${timeDisplay}`
  }
}