import UIActionTypes from  '../constants/UIActionTypes';
import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import find from 'lodash/find';
import some from 'lodash/some';
import reduce from 'lodash/reduce';

export const initialState = {
  itemId: undefined,
  favoriteModifiers: {},
  modifiersForAPI: {},
  modifierGroupsForUI: {},
  suggestedItemsForAPI: {},
  suggestedItemsForUI: []
};

const modifiersGroupsFromFavorite = (favorite_modifier_groups, modifier_groups) => {
  const modifierGroups = modifier_groups.map((modGroup) => {
      const selections = get(
          find(favorite_modifier_groups, { id: get(modGroup, 'id') }),
          'modifiers'
      )

      const modifiers = modGroup.modifiers
      .map((mod) => {
          const default_option = some(selections, {id: mod.id});
          return { ...mod, default_option }
      });
      return { ...modGroup, modifiers };

  });
  return modifierGroups;
};

const modifierGroupsToModifiers = (modifier_groups) => {
  const modifiers = reduce(modifier_groups, (outerSum, modGroup) => {
      outerSum[modGroup.id] = reduce(modGroup.modifiers, (sum, mod) => {
          sum[mod.id] = mod.default_option;
          return sum
      }, {});

      return outerSum;
  }, {});
  return modifiers;
};

export default createReducer(
  initialState,
  {
    [ActionTypes.ITEM_LOAD_SUCCESS]: (state, action) => {
      const itemId =  get(action, 'payload.id');
      const favoriteModifierGroups = get(state, `favoriteModifiers[${itemId}].modifier_groups`, []);
      const modifier_groups =  get(action, 'payload.modifier_groups');
      const suggestedItemsForUI =  get(action, 'payload.suggested_items');
      const mergedModifierGroups = favoriteModifierGroups.length > 0 ?
        modifiersGroupsFromFavorite(favoriteModifierGroups, modifier_groups) : modifier_groups;

      const modifiersForAPI = modifierGroupsToModifiers(mergedModifierGroups);
      const modifierGroupsForUI = { [itemId]: { modifier_groups: mergedModifierGroups } };
      return { ...state, itemId, suggestedItemsForUI, suggestedItemsForAPI: {}, modifiersForAPI, modifierGroupsForUI }
    },

    [ActionTypes.FAVORITE_LOAD_SUCCESS]: (state, action) => {
      const payload = get(action, 'payload');
      const id = get(payload, 'item.id');
      const modifier_groups = get(payload, 'all_selected_modifiers', [])
        .map((val) => ({
          id: val.group_id,
          modifiers: val.selections
        }));
      const favoriteModifiers = { [id]: { modifier_groups } };
      const initialItemModifiers = get(state, `modifierGroupsForUI[${id}].modifier_groups`, []);

      let itemModifiers = {};
      if (initialItemModifiers.length > 0) {
        const mergedModifierGroups = modifiersGroupsFromFavorite(modifier_groups, initialItemModifiers);
        const modifierGroupsForUI = { [id]: { modifier_groups: mergedModifierGroups } };
        const modifiersForAPI = modifierGroupsToModifiers(mergedModifierGroups);
        itemModifiers = { modifierGroupsForUI, modifiersForAPI };
      }
      return { ...state, ...itemModifiers, favoriteModifiers  };
    },

    [UIActionTypes.SET_ITEM_DETAILS_STATE]:
      (state, action) => ({ ...state, itemId: action.payload }),

    [UIActionTypes.SET_ITEM_MODIFIERS]: (state, action) => {
      const payload = get(action, 'payload');
      const modifiersState = get(state, 'modifiersForAPI');
      const modifiersForAPI = { ...modifiersState, ...payload };
      return { ...state, modifiersForAPI };
    },

    [UIActionTypes.SET_ITEM_SUGGESTIONS]: (state, action) => {
      const suggestedItemsForAPI = get(action, 'payload');
      return { ...state, suggestedItemsForAPI };
    }
  }
);
