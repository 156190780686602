import { createActions } from 'redux-actions';
import ActionTypes from '../constants/ActionTypes';
import UIActionTypes from '../constants/UIActionTypes';
import HttpClient from '../core/HttpClient';

const ACCOUNT_BALANCE_PATH = '/api/wallet';
const GIFT_CARDS_PATH = '/api/gift_cards/redeem';

export default createActions(
  {
    [ActionTypes.ACCOUNT_BALANCE_LOAD]: () => ({
      data: {},
      promise: HttpClient.get(ACCOUNT_BALANCE_PATH)
    }),
    [ActionTypes.GIFT_CARD_REDEEM]: (code) => ({
      data: {},
      promise: HttpClient.post(
        GIFT_CARDS_PATH,
        { code }
      )
    }),
  },
  `${UIActionTypes.UPDATE_TIP_AMOUNT}`,
  `${UIActionTypes.SET_PROMO_CODE}`,
  `${UIActionTypes.CLEAR_PROMO_CODE}`,
  `${UIActionTypes.SET_SAVED_CARDS}`,
  `${UIActionTypes.REMOVE_SAVED_CARDS}`,
  `${UIActionTypes.SET_PAYMENT_UI_DATA}`,
  `${UIActionTypes.REMOVE_PAYMENT_UI_DATA}`,
  `${UIActionTypes.SET_CARD_ERROR}`,
  `${UIActionTypes.SET_SELECTED_CARD}`,
  `${UIActionTypes.SET_CARD_MANAGER_MODE}`,
  `${UIActionTypes.SET_REMEMBER_CARD}`,
  `${UIActionTypes.SET_ACCOUNT_BALANCE_USED}`,
  `${UIActionTypes.SET_UI_FUNDS_LOCK}`,
  `${UIActionTypes.CLEAR_GIFT_CARD}`,
  `${UIActionTypes.STORE_REDEMPTION_QUERY_PARAM}`
);
