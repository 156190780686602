import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';

const initialState = {
  list: [],
  limit: 20,
  offset: 0,
  total: undefined,
  errors: {},
  loading: false
};

export default createReducer(
  initialState,
  {
    [ActionTypes.USER_HISTORY_REQUEST]: (state, action) => {
      return { ...state, loading: true }
    },

    [ActionTypes.USER_HISTORY_SUCCESS]: (state, action) => {
      const list =  get(action, 'payload.list', []);
      const meta =  get(action, 'payload.meta', {});
      return { ...state, ...meta, list, loading: false }
    },

    [ActionTypes.USER_HISTORY_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.error');
      return { ...state, loading: false, errors };
    }
  }
);
