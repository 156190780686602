import ActionTypes from '../constants/UIActionTypes';
import UserActionTypes from '../constants/ActionTypes'
import {
  SHOW_DEFAULT,
  SHOW_VERIFY_PHONE_V1,
  SHOW_PHONE_SIGN_IN_CREATE_PROFILE,
  SHOW_FINISH_ACCOUNT,
  SHOW_SIGN_IN,
  SHOW_ORDER_CHANGE_PHONE,
  LOCATION_INPUT_ADDRESS
} from '../constants/sharedKeys';
import createReducer from '../core/createReducer';
import get from 'lodash/get';

const initialState = {
  displaySigninSignUpModal: false,
  signinSignUpModalState: SHOW_DEFAULT,
  showAccountCreated: false,
  displayBagFlyout: false,
  showItemDetailsModal: false,
  itemDetailsModalState: undefined,
  showSidesAndDrinksModal: false,
  sidesAndDrinksModalState: undefined,
  displayHeaderLocationInput: false,
  displayGroupOrderWalkthrough: false,
  displayStartGroupOrderModal: false,
  displayInviteFriendsModal: false,
  displayLeaveGroupModal: false,
  displayCancelGroupModal: false,
  displayCheckoutGroupModal: false,
  displayHelpModal: false,
  displayInfoModal: false,
  displayOnboardingModal: false,
  displayDropoffHelpModal: false,
  locationInputModalState: {
    step: LOCATION_INPUT_ADDRESS,
    useNearestKitchen: true,
    useOrderAddress: false
  },
  locationModalTriggered: false,
  displayFreeDeliveryThresholdModal: false,
  notificationModal: { display: false }
};

const getModalStateBasedOnUser = (user) => {
  if (user && user.state === 'pending') {
    return SHOW_PHONE_SIGN_IN_CREATE_PROFILE
  }

  if (user && !user.phone_number) {
    return SHOW_FINISH_ACCOUNT
  }

  if (user && !user.phone_number_verified) {
    return SHOW_VERIFY_PHONE_V1
  }

  return SHOW_DEFAULT
}

const displayModalBasedOnUser = (user) => {
  if (user && user.phone_number && user.phone_number_verified) {
    return false
  }
  return true
}

const userAuthHandler = (state, action) => ({
  ...state,
  displaySigninSignUpModal: displayModalBasedOnUser(action.payload.user),
  signinSignUpModalState:   getModalStateBasedOnUser(action.payload.user)
})

const showModalInitialState = (state, action) => {
  const displaySigninSignUpModal = get(action, 'payload');
  return {
    ...state,
    signinSignUpModalMessage: null,  // Reset any saved message
    displaySigninSignUpModal
  };
}

export default createReducer(
  initialState,
  {
    [UserActionTypes.USER_AUTH_SUCCESS]: userAuthHandler,
    [UserActionTypes.USER_LOAD_SUCCESS]: (state, action) => {
      if (state.signinSignUpModalState === SHOW_ORDER_CHANGE_PHONE) {
        return { ...state }
      } else {
        return {
          ...state,
          signinSignUpModalState: getModalStateBasedOnUser(action.payload)
        }
      }
    },
    [ActionTypes.SHOW_SIGNIN_SIGNUP_MODAL]: showModalInitialState,
    [ActionTypes.SET_SIGNIN_SIGNUP_MODAL_STATE]:
      (state, action) => ({ ...state, signinSignUpModalState: action.payload }),
    [ActionTypes.SET_SIGNIN_SIGNUP_MODAL_MESSAGE]:
      (state, action) => ({ ...state, signinSignUpModalMessage: action.payload }),
    [ActionTypes.PASSWORD_RESET_SUCCESS]:
      (state, action) => ({
        ...state,
        displaySigninSignUpModal: true,
        signinSignUpModalState: SHOW_SIGN_IN,
        signinSignUpModalMessage: 'Your password was updated'
      }),
    [ActionTypes.SHOW_ACCOUNT_CREATED]:
      (state, action) => ({ ...state, showAccountCreated: action.payload }),
    [ActionTypes.SET_DISPLAY_BAG_FLYOUT]:
      (state, action) => {
        const displayBagFlyout = get(action, 'payload');
        return { ...state, displayBagFlyout };
      },
      [ActionTypes.SHOW_ITEM_DETAILS_MODAL]:
      (state, action) => ({ ...state, showItemDetailsModal: action.payload }),
      [ActionTypes.SET_ITEM_DETAILS_STATE]:
      (state, action) => ({ ...state, showItemDetailsModal: true, itemDetailsModalState: action.payload }),
      [ActionTypes.SHOW_SIDES_AND_DRINKS_MODAL]:
      (state, action) => ({ ...state, showSidesAndDrinksModal: action.payload }),
      [ActionTypes.SET_SIDES_AND_DRINKS_STATE]:
      (state, action) => ({ ...state, showSidesAndDrinksModal: true, sidesAndDrinksModalState: action.payload }),
      [ActionTypes.SET_DISPLAY_HEADER_LOCATION_INPUT]:
      (state, action) => ({ ...state, displayHeaderLocationInput: action.payload }),
      [ActionTypes.SHOW_GROUPORDER_WALKTHROUGH_MODAL]:
      (state, action) => ({ ...state, displayGroupOrderWalkthrough: action.payload }),
      [ActionTypes.SHOW_START_GROUPORDER_MODAL]:
      (state, action) => ({ ...state, displayStartGroupOrderModal: action.payload }),
      [ActionTypes.SHOW_INVITE_FRIENDS_MODAL]:
      (state, action) => ({ ...state, displayInviteFriendsModal: action.payload }),
      [ActionTypes.SHOW_LEAVE_GROUP_MODAL]:
      (state, action) => ({ ...state, displayLeaveGroupModal: action.payload }),
      [ActionTypes.SHOW_CANCEL_GROUP_MODAL]:
      (state, action) => ({ ...state, displayCancelGroupModal: action.payload }),
      [ActionTypes.SHOW_CHECKOUT_GROUP_MODAL]:
      (state, action) => ({ ...state, displayCheckoutGroupModal: action.payload }),
      [ActionTypes.SHOW_HELP_MODAL]:
      (state, action) => ({ ...state, displayHelpModal: action.payload }),
      [ActionTypes.SHOW_INFO_MODAL]:
      (state, action) => ({ ...state, displayInfoModal: action.payload }),
      [ActionTypes.SHOW_ONBOARDING_MODAL]:
      (state, action) => ({ ...state, displayOnboardingModal: action.payload }),
      [ActionTypes.SET_DISPLAY_DROP_OFF_HELP_MODAL]:
      (state, action) => ({ ...state, displayDropoffHelpModal: action.payload }),
      [ActionTypes.SET_LOCATION_INPUT_MODAL_STATE]:
      (state, action) => {
        const payload = get(action, 'payload', {});
        const locationInputModalState = {...state.locationInputModalState, ...payload};
        return { ...state, locationInputModalState };
      },
      [ActionTypes.SET_LOCATION_MODAL_TRIGGERED]:
      (state, action) => ({ ...state, locationModalTriggered: action.payload }),
      [ActionTypes.SET_DISPLAY_FREE_DELIVERY_THRESHOLD_MODAL]:
      (state, action) => ({ ...state, displayFreeDeliveryThresholdModal: action.payload }),
      [ActionTypes.SHOW_NOTIFICATION_MODAL]:
      (state, action) => {
        return {
          ...state,
          notificationModal: {
            display: true,
            title: get(action, 'payload.title'),
            text: get(action, 'payload.text'),
            image: get(action, 'payload.image')
          }
        }
      },
      [ActionTypes.HIDE_NOTIFICATION_MODAL]:
      (state, action) => {
        return {
          ...state,
          notificationModal: {
            display: false
          }
        }
      }
  }
);
