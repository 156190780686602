import { createActions } from "redux-actions";
import ActionTypes from "../constants/ActionTypes";
import HttpClient from "../core/HttpClient";
import { V2_ACCEPT_HEADER, V4_ACCEPT_HEADER } from "../core/HttpClient";

const getKitchenIdOrSlug = idOrSlug => {
  let kitchenIdOrSlug = idOrSlug;
  if (!kitchenIdOrSlug) {
    kitchenIdOrSlug = window.location.host.split(".")[0];
    if (kitchenIdOrSlug === "www") {
      kitchenIdOrSlug = "downtown-indy";
    } else if (kitchenIdOrSlug.indexOf("localhost") !== -1) {
      kitchenIdOrSlug = "downtown-indy";
    }
  }

  return kitchenIdOrSlug;
};

export default createActions(
  {
    [ActionTypes.MENU_LOAD]: () => {
      return {
        promise: HttpClient.get("/api/menu", {}, null, V4_ACCEPT_HEADER)
      };
    },
    [ActionTypes.KITCHEN_LOAD]: idOrSlug => {
      const kitchenIdOrSlug = getKitchenIdOrSlug(idOrSlug);
      return {
        promise: HttpClient.get(
          `/api/kitchens/${kitchenIdOrSlug}`,
          {},
          null,
          V2_ACCEPT_HEADER
        )
      };
    },

    [ActionTypes.SCHEDULED_SLOTS_LOAD]: idOrSlug => {
      const kitchenIdOrSlug = getKitchenIdOrSlug(idOrSlug);
      return {
        promise: HttpClient.get(
          `/api/kitchens/${kitchenIdOrSlug}/schedule_ranges`,
          {},
          null
        )
      };
    },

    [ActionTypes.VISIBLE_PLACES_LOAD]: id => ({
      promise: HttpClient.get(`/api/kitchens/${id}/places`)
    })
  },
  `${ActionTypes.SCHEDULED_SLOTS_CLEAR}`
);
