import ActionTypes from '../constants/ActionTypes';
import { UTM_SOURCE_KEY } from '../constants/sharedKeys';
import createReducer from '../core/createReducer';
import cookieUtils from '../utils/cookieUtils';
import get from 'lodash/get';

const initialState = {
  utmSrc: cookieUtils.getCookie(UTM_SOURCE_KEY),
}

export default createReducer(
  initialState,
  {
    [ActionTypes.NEW_UTM_SOURCE]: (state, action) => {
      const utmSrc = get(action, 'payload')
      cookieUtils.setCookie(UTM_SOURCE_KEY, utmSrc)
      return { ...state, utmSrc };
    },

    [ActionTypes.CLEAR_UTM_SOURCE]: (state, action) => {
      cookieUtils.deleteCookie(UTM_SOURCE_KEY)
      return { ...state, utmSrc: null }
    },
  }
)
