import { createActions } from 'redux-actions';
import UIActionTypes from '../constants/UIActionTypes';
import ActionTypes from '../constants/ActionTypes';
import HttpClient from '../core/HttpClient';

export default createActions(
  {
    [ActionTypes.KITCHEN_STATUS_LOAD]: () => ({
      promise: HttpClient.get('/api/kitchen/status')
    })
  },
  `${UIActionTypes.LISTEN_TO_KITCHEN_CHANNEL}`,
  `${UIActionTypes.UPDATE_WAIT_TIME_ESTIMATE}`,
  `${UIActionTypes.KITCHEN_OPEN}`,
  `${UIActionTypes.KITCHEN_ANNOUNCEMENT}`
);