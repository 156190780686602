import ActionTypes from '../constants/ActionTypes';
import UIActionTypes from  '../constants/UIActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import omitBy from 'lodash/omitBy';
import { USER_KEY, ORDER_KEY } from '../constants/sharedKeys';
import ls from 'local-storage';
import { track, identify, page } from '../analytics/customerio';
import { defaultProps } from '../analytics/defaultAnalyticsProps';
import map from 'lodash/map';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import cookieUtils from '../utils/cookieUtils';

const ct_session_id = cookieUtils.getCookie('_ct_session_id');
const user = ls.get(USER_KEY);
const order = ls.get(ORDER_KEY);
if (user) {
  identify(user);
}

const onAuthSuccess = (state, action) => {
  const user = get(action, 'payload.user');
  identify(user);
  return state;
};

const onSignUpSuccess = (state, action) => {
  const user = get(action, 'payload');
  identify(user);
  return state;
};

const onLocationChange = (state, action) => {
  const pathname = get(action, 'payload.location.pathname');
  const sessionId = state.ct_session_id
  page(pathname, { sessionId });
  return state;
};

const getProducts = (order={}) => (
  map(
    order.line_items,
    (item) => ({
      product_id: item.id,
      sku: item.id,
      name: item.name,
      category: item.truck_name,
      price: item.price,
      quantity: 1,
    })
  )
);
const getOrder = (order) => {
  const price_lines = get(order, 'price_lines', {});
  const promoDiscount = find(
    price_lines,
    ({ name }) => ( name === 'Promo Discount' )
  );
  const pointsDiscount = find(
    price_lines,
    ({ name }) => ( name === 'Points Discount' )
  );
  const deliveryFee = find(
    price_lines,
    ({ name }) => ( name === 'Delivery Fee' )
  );


  return {
    products: order ? getProducts(order) : null,
    revenue: order ? order.total / 100 : 0,
    tax: order ? order.tax / 100 : 0,
    payment_method: get(order, 'credit_card.brand', null),
    discount: (get(promoDiscount, 'value', 0) + get(pointsDiscount, 'value', 0)) / 100,
    shipping: get(deliveryFee, 'value', 0),
    currency: 'USD'
  }

}

const orderSuccess = (state, action) => {
  const order = get(action, 'payload', {});
  const updates = {
    cart_id: order.id,
    order: getOrder(order)
  }
  return { ...state, ...updates }
};

const clearOrder = (state) => {
  return {
    ...state,
    cart_id: null,
    order: null
  };
};

const itemSuccess = (state, action) => {
  const item =  get(action, 'payload', {});
  const { id, name, price, trucks } = item;
  const category = get(trucks, '[0].name');
  const properties = {
      product_id: id,
      sku: id,
      name,
      category,
      price: price / 100,
      quantity: 1
  };

  return { ...state, item: properties }
};

const onTrack = (state, action) => {
  const name = get(action, 'payload.name');
  const props = get(action, 'payload.props');
  track(name, props);
  return state;
};

const onEnhancedTrack = (state, action) => {
  const name = get(action, 'payload.name');
  const props = get(action, 'payload.properties', {});
  const dataAttributes = get(action, 'payload.dataAttributes', {});
  action.sideEffect((store) => {
    const updatedProps = omitBy(
      reduce(
        { ...defaultProps, ...props },
        (sum, value, key) => {
          const storeMatch = typeof value === 'string' ? get(value.match(/\[\[(.*?)\]\]/), '[1]') : false;
          sum[key] = storeMatch ? get(store.getState(), storeMatch) : value;
          return sum;
        },
        { ...dataAttributes }
      ),
      v => isNil(v)
    );
    track(name, updatedProps);
  });
  return state;
};

const onSetTreatments = (state, action) => {
  const treatments = get(action, 'payload');
  identify(null, {treatments});
  return state;
};

export const initialState = {
  list_id: 'Menu',
  item: null,
  cart_id: order ? order.id : null,
  order: getOrder(order),
  ct_session_id
};

export default createReducer(
  initialState,
  {
    [ActionTypes.ORDER_CREATE_SUCCESS]: orderSuccess,
    [ActionTypes.ORDER_UPDATE_SUCCESS]: orderSuccess,
    [ActionTypes.ORDER_CREATE_OR_UPDATE_SUCCESS]: orderSuccess,
    [ActionTypes.START_GROUP_CHECKOUT]: orderSuccess,
    [ActionTypes.ORDER_LOAD_SUCCESS]: orderSuccess,
    [ActionTypes.ORDER_CONFIRM_SUCCESS]: clearOrder,
    [ActionTypes.CLEAR_ORDER]: clearOrder,
    [ActionTypes.ITEM_LOAD_SUCCESS]: itemSuccess,

    [ActionTypes.USER_PHONE_AUTH_LOGIN_SUCCESS]: onAuthSuccess,
    [ActionTypes.USER_PHONE_AUTH_REGISTER_SUCCESS]: onSignUpSuccess,
    '@@router/LOCATION_CHANGE': onLocationChange,

    [UIActionTypes.TRACK]: onTrack,
    [UIActionTypes.ENHANCED_TRACK]: onEnhancedTrack,
    [UIActionTypes.SET_TREATMENTS]: onSetTreatments,
    [UIActionTypes.SET_SESSION_ID]: (state, action) => {
      const ct_session_id = get(action, 'payload');
      return { ...state, ct_session_id };
    }
  }
);
