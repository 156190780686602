import ActionTypes from '../constants/ActionTypes';
import { USER_KEY, SHOW_VERIFY_PHONE, SHOW_PHONE_SIGN_IN_VERIFY, SHOW_PHONE_SIGN_IN_CREATE_PROFILE, SHOW_PHONE_SIGN_IN_RECOVERY_SENT, SHOW_PHONE_SIGN_IN_RECOVERY_MERGE, USER_UTM_SOURCE_KEY } from '../constants/sharedKeys';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import ls from 'local-storage';
import { isAuthenticated } from '../utils/isAuthenticated';
import UIActions from '../actionCreators/UIActions';
import FavoritesActions from '../actionCreators/FavoritesActions';
import KitchenActions from '../actionCreators/KitchenActions';
import ConfirmedOrderActions from '../actionCreators/ConfirmedOrderActions';
import { push } from 'connected-react-router';
import cookieUtils from '../utils/cookieUtils';

let EMPLOYEE_ROLE = 'employee';

const isEmployee = (user) => includes(user.roles || [], EMPLOYEE_ROLE);

const isSignupComplete = (user) => {
  if (!user) {
    return false
  }
  return user.email && user.phone_number && user.phone_number_verified
}

const userSuccess = (state, user, action) => {
  ls.set(USER_KEY, user);

  action.sideEffect((store) => {
    const userId = get(user, 'id');
    const storeState = store.getState();
    const pathname =  get(storeState, 'router.locationBeforeTransitions.pathname', '');
    const isMenuPage = pathname.indexOf('/menu') >= 0;
    if(isMenuPage) {
      store.dispatch(KitchenActions.menuLoad())
    }
    store.dispatch(FavoritesActions.favoritesLoad());
    store.dispatch(ConfirmedOrderActions.joinCurrentOrderChannel(userId));
  })

  return {
    ...state,
    user,
    isAuthenticated: true,
    isSignupComplete: isSignupComplete(user),
    token: user.token,
    isEmployee: isEmployee(user),
    isSaving: false,
    errors: {}
  };
};

const userError = (state, action, errors) => {
  return { ...state,
    user: null,
    isAuthenticated: false,
    isSignupComplete: false,
    token: null,
    isEmployee: false,
    isSaving: false,
    errors
  };
};

let parsed_user = null
try {
  parsed_user = ls.get(USER_KEY)
}
catch (ex) {}
const _user = parsed_user
let _isAuthenticated = false;
let _isEmployee = false;
let _isSignupComplete = false

if (_user) {
  _isAuthenticated = isAuthenticated(_user.token);
  _isEmployee = isEmployee(_user);
  _isSignupComplete = isSignupComplete(_user)
}

const initialState = {
  user: _user,
  isSaving: false,
  errors: {},
  isSignupComplete: _isSignupComplete,
  isAuthenticated: _isAuthenticated,
  isEmployee: _isEmployee,
  authPhoneNumber: null,
  authEmail: null,
  utmSrc: cookieUtils.getCookie(USER_UTM_SOURCE_KEY),
};

export default createReducer(
  initialState,
  {
    [ActionTypes.USER_LOAD_SUCCESS]: (state, action) => {
      const token = get(state, 'user.token');
      let user = get(action, 'payload');
      if (token) {
        user.token = token;
      }
      return userSuccess(state, user, action);
    },
    [ActionTypes.USER_LOAD_ERROR]: (state, action) => {
      const statusCode = get(action, 'payload.response.statusCode');
      const errors = (statusCode === 401) ? { authError: true } : {};
      return userError(state, action, errors);
    },

    [ActionTypes.USER_UPDATE_REQUEST]: (state, action) => {
      return { ...state, isSaving: true, errors: {} };
    },
    [ActionTypes.USER_UPDATE_SUCCESS]: (state, action) => {
      const token = get(state, 'user.token');
      let user = get(action, 'payload');
      if (token) {
        user.token = token;
      }
      return userSuccess(state, user, action);
    },
    [ActionTypes.USER_UPDATE_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.details');
      return { ...state, isSaving: false, errors };
    },

    [ActionTypes.USER_UPDATE_SEND_VERIFICATION_REQUEST]: (state, action) =>
    ({ ...state, isSaving: true }),
    [ActionTypes.USER_UPDATE_SEND_VERIFICATION_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.details') || get(action, 'payload.response.body.description');
      return { ...state, isSaving: false, errors };
    },

    [ActionTypes.USER_UPDATE_VALIDATE_PHONE_REQUEST]: (state, action) =>
    ({ ...state, errors: {}, isSaving: true }),
    [ActionTypes.USER_UPDATE_VALIDATE_PHONE_ERROR]: (state, action) => {
      const errors = {phone_number_token_confirmation: 'Incorrect code, please try again'};
      return { ...state, isSaving: false, errors };
    },

    [ActionTypes.USER_UPDATE_VALIDATE_PHONE_SUCCESS]: (state, action) => {
      return userSuccess(state, action.payload, action)
    },

    [ActionTypes.USER_UPDATE_CHANGE_PHONE_REQUEST]: (state, action) => (
      { ...state, isSaving: true, authPhoneNumber: get(action, 'payload.phone_number'), errors: {} }
    ),
    [ActionTypes.USER_UPDATE_CHANGE_PHONE_SUCCESS]: (state, action) => {
      action.sideEffect((store) => {
        store.dispatch(UIActions.setSigninSignupModalState(SHOW_VERIFY_PHONE))
      })
      return { ...state, errors: {}, isSaving: false }
    },
    [ActionTypes.USER_UPDATE_CHANGE_PHONE_ERROR]: (state, action) => {
      const errors = { ...get(action, 'payload.response.body.details', {}) }
      return { ...state, isSaving: false, errors };
    },

    [ActionTypes.USER_UPDATE_VERIFY_PHONE_REQUEST]: (state, action) => (
      { ...state, isSaving: true, authPhoneNumber: get(action, 'payload.phone_number'), errors: {} }
    ),
    [ActionTypes.USER_UPDATE_VERIFY_PHONE_SUCCESS]: (state, action) => {
      action.sideEffect((store) => {
        store.dispatch(UIActions.showSigninSignupModal(false))
      })
      let user = get(action, 'payload')
      return {
        ...userSuccess(state, user, action),
        authPhoneNumber: null
      }
    },
    [ActionTypes.USER_UPDATE_VERIFY_PHONE_ERROR]: (state, action) => {
      const errors = {auth_code: 'Incorrect code, please try again'};
      return { ...state, isSaving: false, errors };
    },

    [ActionTypes.USER_AUTH_REQUEST]: (state, action) => {
      return { ...state, isSaving: true, errors: {} };
    },
    [ActionTypes.USER_AUTH_SUCCESS]: (state, action) => {
      const user = get(action, 'payload.user');
      return userSuccess(state, user, action);
    },
    [ActionTypes.USER_AUTH_ERROR]: (state, action) => {
      const statusCode = get(action, 'payload.response.statusCode');
      const errors = (statusCode === 401) ? { authError: true } : {};
      return userError(state, action, errors);
    },

    [ActionTypes.USER_RESET_PASSWORD_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.details');
      return { ...state, isSaving: false, errors };
    },

    [ActionTypes.USER_SIGNUP_SUCCESS]: (state, action) => {
      const user = get(action, 'payload');
      return userSuccess(state, user, action);
    },
    [ActionTypes.USER_SIGNUP_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.details');
      return userError(state, action, errors);
    },

    [ActionTypes.USER_LOGOUT]: (state, action) => {
      ls.remove(USER_KEY);
      return {
        ...state,
        user: null,
        isSignupComplete: false,
        isAuthenticated: false,
        token: null,
        isEmployee: false,
        isSaving: false,
        errors: {}
      };
    },

    [ActionTypes.USER_UPDATE_PASSWORD_REQUEST]: (state, action) => {
      return { ...state, isSaving: true, passwordUpdated: false }
    },

    [ActionTypes.USER_UPDATE_PASSWORD_SUCCESS]: (state, action) => {
      return { ...state, isSaving: false, passwordUpdated: true }
    },

    [ActionTypes.USER_UPDATE_PASSWORD_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.details');
      return { ...state, isSaving: false, errors };
    },

    [ActionTypes.USER_PHONE_AUTH_CODE_REQUEST]: (state, action) => {
      return { ...state, isSaving: true, authPhoneNumber: get(action, 'payload.phone_number'), errors: {} }
    },
    [ActionTypes.USER_PHONE_AUTH_CODE_SUCCESS]: (state, action) => {
      action.sideEffect((store) => {
        store.dispatch(UIActions.setSigninSignupModalState(SHOW_PHONE_SIGN_IN_VERIFY))
      })
      return { ...state, isSaving: false }
    },
    [ActionTypes.USER_PHONE_AUTH_CODE_ERROR]: (state, action) => {
      const errors = { ...get(action, 'payload.response.body.details', {}) }
      return userError(state, action, errors)
    },

    [ActionTypes.USER_PHONE_AUTH_LOGIN_REQUEST]: (state, action) => (
      { ...state, isSaving: true, authPhoneNumber: get(action, 'payload.phone_number'), errors: {} }
    ),
    [ActionTypes.USER_PHONE_AUTH_LOGIN_SUCCESS]: (state, action) => {
      const user = get(action, 'payload.user');
      const success = userSuccess(state, user, action);

      const { recovery } = state;
      action.sideEffect((store) => {
        if (!isEmpty(recovery)) {
          store.dispatch(UIActions.setSigninSignupModalState(SHOW_PHONE_SIGN_IN_RECOVERY_MERGE))
        }
        else if (user.state === 'pending') {
          store.dispatch(UIActions.setSigninSignupModalState(SHOW_PHONE_SIGN_IN_CREATE_PROFILE))
        }
        else {
          store.dispatch(UIActions.showSigninSignupModal(false))
        }
      })

      return {
        ...success,
        authPhoneNumber: null
      }
    },
    [ActionTypes.USER_PHONE_AUTH_LOGIN_ERROR]: (state, action) => {
      const statusCode = get(action, 'payload.response.statusCode')
      const errors = (statusCode === 401) ? { authError: true } : { unknownError: true }
      return userError(state, action, errors)
    },

    [ActionTypes.USER_MAGIC_LINK_AUTH_LOGIN_REQUEST]: (state, action) => (
      { ...state, isSaving: true, authPhoneNumber: get(action, 'payload.phone_number'), errors: {} }
    ),
    [ActionTypes.USER_MAGIC_LINK_AUTH_LOGIN_SUCCESS]: (state, action) => {
      const user = get(action, 'payload.user');
      const success = userSuccess(state, user, action);

      return {
        ...success,
        authPhoneNumber: null
      }
    },
    [ActionTypes.USER_MAGIC_LINK_AUTH_LOGIN_ERROR]: (state, action) => {
      const statusCode = get(action, 'payload.response.statusCode')
      const errors = (statusCode === 401) ? { authError: true } : { unknownError: true }
      return userError(state, action, errors)
    },

    [ActionTypes.USER_PHONE_AUTH_REGISTER_REQUEST]: (state, action) => {
      return { ...state, isSaving: true, errors: {} };
    },
    [ActionTypes.USER_PHONE_AUTH_REGISTER_SUCCESS]: (state, action) => {
      action.sideEffect((store) => {
        store.dispatch(UIActions.showSigninSignupModal(false))
      })

      const token = get(state, 'user.token')
      let user = get(action, 'payload')
      if (token) {
        user.token = token
      }
      return userSuccess(state, user, action)
    },
    [ActionTypes.USER_PHONE_AUTH_REGISTER_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.details');
      return { ...state, isSaving: false, errors };
    },

    [ActionTypes.USER_PHONE_AUTH_RECOVERY_REQUEST]: (state, action) => {
      return { ...state, isSaving: true, authEmail: get(action, 'payload.email'), errors: {} };
    },
    [ActionTypes.USER_PHONE_AUTH_RECOVERY_SUCCESS]: (state, action) => {
      action.sideEffect((store) => {
        store.dispatch(UIActions.setSigninSignupModalState(SHOW_PHONE_SIGN_IN_RECOVERY_SENT));
      });

      return {
        ...state,
        isSaving: false
      };
    },
    [ActionTypes.USER_PHONE_AUTH_RECOVERY_ERROR]: (state, action) => {
      const body = get(action, 'payload.response.body', {});
      const errors = get(body, 'details', body);
      return { ...state, isSaving: false, authEmail: null, errors };
    },

    [ActionTypes.SET_USER_PHONE_AUTH_RECOVERY_INFO]: (state, action) => ({
      ...state,
      recovery: {...action.payload}
    }),

    [ActionTypes.USER_PHONE_AUTH_RECOVERY_VERIFY_REQUEST]: (state, action) => {
      return { ...state, isSaving: true, errors: {} };
    },
    [ActionTypes.USER_PHONE_AUTH_RECOVERY_VERIFY_SUCCESS]: (state, action) => {
      action.sideEffect((store) => {
        store.dispatch(push('/menu'));
      });

      const user = get(action, 'payload');
      return userSuccess({
        ...state,
        recovery: {}
      }, user, action);
    },
    [ActionTypes.USER_PHONE_AUTH_RECOVERY_VERIFY_ERROR]: (state, action) => {
      const body = get(action, 'payload.response.body', {});
      const errors = get(body, 'details', body);
      return { ...state, isSaving: false, errors };
    },

    // Handle this MarketingAction but store the value separately. We need to keep and clear it independently
    // of the order
    [ActionTypes.NEW_UTM_SOURCE]: (state, action) => {
      const utmSrc = get(action, 'payload')
      cookieUtils.setCookie(USER_UTM_SOURCE_KEY, utmSrc);
      return { ...state, utmSrc };
    },

    [ActionTypes.SAVE_USER_SOURCE_SUCCESS]: (state, action) => {
      cookieUtils.deleteCookie(USER_UTM_SOURCE_KEY);
      return { ...state, utmSrc: null };
    },

    [ActionTypes.GROUPORDER_CREATE_SUCCESS]: (state, action) => {
      const traits = get(action, 'payload.user.traits', [])
      const user = {
        ...state.user,
        traits: traits
      }

      ls.set(USER_KEY, user)

      return {
        ...state,
        user: user
      }
    },
  }
);
