export default (store) => {
  const sideEffects = [];
  return (next) => (action) => {
    action.sideEffect = (effect) => sideEffects.push(effect);
    const result = next(action);
    while (sideEffects.length > 0) {
      sideEffects.shift()(store);
    }
    return result;
  };
};
