import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import toLower from 'lodash/toLower';

export const initialState = { suggestions: {}, loading: false };

export default createReducer(
  initialState,
  {
    [ActionTypes.BAG_SUGGESTIONS_LOAD_REQUEST]: (state, action) =>
      ({ ...state, loading: true }),

    [ActionTypes.BAG_SUGGESTIONS_LOAD_SUCCESS]: (state, action) => {
      const suggestions =  get(action, 'payload', []);
      return {
        ...state,
        loading: false,
        suggestions: mapValues(keyBy(suggestions, category => toLower(category['name'])), category => category['items'])
      };
    },

    [ActionTypes.BAG_SUGGESTIONS_LOAD_ERROR]: (state, action) => {
      const errors = get(action, 'payload.response.body.error');
      return { ...state, loading: false, errors };
    }
  }
);
