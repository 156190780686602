export default {
  // note: getCookie returns the first value found if there is more than one cookie with same key
  getCookie: (key, document = window.document) => (
    document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)')?.pop() || null
  ),

  setCookie: (key, value, expires, document = window.document) => {
    if (typeof expires === 'undefined') {
      expires = new Date(new Date().getTime() + (365 * 86400000)).toUTCString();
    }
    document.cookie =
      `${key}=${value};path=/${expires ? `;expires=${expires}` : ''};domain=${window.ENV.ENV_DOMAIN}`;
  },

  deleteCookie: (key, document = window.document) => {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${window.ENV.ENV_DOMAIN}`;
  }
};