import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';

const initialState = {
  team: { },
  joined: false
}

export default createReducer(
  initialState,
  {
    [ActionTypes.FETCH_TEAM_SUCCESS]: (state, action) => (
      {
        ...state,
        team: {
          data: action.payload
        }
      }
    ),
    [ActionTypes.JOIN_TEAM_SUCCESS]: (state, action) => (
      {
        ...state,
        joined: true
      }
    )
  }
)
