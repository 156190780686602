import React from 'react';
import ErrorBox from '../components/ErrorBox/ErrorBox2';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{margin: '-50px 20px 0'}}>
          <ErrorBox errorMessage='Error loading component' />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
