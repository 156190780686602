import { createActions } from 'redux-actions';
import ActionTypes from '../constants/ActionTypes';
import HttpClient from '../core/HttpClient';
import UIActionTypes from '../constants/UIActionTypes';

const ORDER_PATH = '/api/orders';
const orderUrl = (orderNumber) => `${ORDER_PATH}/${orderNumber}`;

export default createActions(
  {
    [ActionTypes.CONFIRMED_ORDER_LOAD]: (orderNumber) => ({
      data: { orderNumber },
      promise: HttpClient.get(orderUrl(orderNumber))
    }),
    [ActionTypes.CONFIRMED_ORDER_UPDATE]: (orderNumber, data) => ({
      data: { orderNumber, data },
      promise: HttpClient.put(orderUrl(orderNumber), data)
    }),
    [ActionTypes.CONFIRMED_ORDER_RATE]: (orderNumber, data) => ({
      data: { orderNumber, data },
      promise: HttpClient.post(`${orderUrl(orderNumber)}/rate`, data)
    }),
    [ActionTypes.ORDER_ISSUE]: (orderNumber, data) => ({
      data: { orderNumber, data },
      promise: HttpClient.post(`${orderUrl(orderNumber)}/issue`, data)
    })
  },
  `${ActionTypes.ORDER_UPDATE_EVENT}`,
  `${UIActionTypes.LISTEN_TO_ORDER_CHANNEL}`,
  `${UIActionTypes.JOIN_CURRENT_ORDER_CHANNEL}`,
  `${UIActionTypes.LEAVE_CURRENT_ORDER_CHANNEL}`,
  `${UIActionTypes.UPDATE_CURRENT_ORDER}`
);
