import React from 'react';
import '../components/Loader/LoaderV2.sass';
import loadingIcon from '../components/Loader/loadingV2.svg';
import ErrorBoundary from'./ErrorBoundary';

const LoadingIcon = () => (
  <div className='spinner-v2'>
    <img src={loadingIcon} alt="loading" />
  </div>
);

const LazyLoadWrapper = ({children}) => {
  return (
    <ErrorBoundary>
      <React.Suspense
        fallback={<LoadingIcon />}>
        {children}
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default LazyLoadWrapper;
