import { useEffect } from 'react'
import { connect } from 'react-redux'
import qs from 'querystringify'
import get from 'lodash/get'
import OrderActions from '../actionCreators/OrderActions'
import MarketingActions from '../actionCreators/MarketingActions'
import UserActions from '../actionCreators/UserActions'

const MarketingTracking = ({ children, userId, userSource, newMarketingSource, newUtmSource, saveUserSource }) => {

  useEffect(() => {
    const queryString = qs.parse(get(window.location, 'search'))
    const marketingSource = get(queryString, 's')
    if (marketingSource) {
      newMarketingSource(marketingSource)
    }

    const utmSource = get(queryString, 'utm_source')
    if (utmSource) {
      newUtmSource(utmSource)
    }
  }, [newMarketingSource, newUtmSource])

  // If we have a user with a utmSource, patch that up to the user
  useEffect(() => {
    if (userId && userSource) {
      saveUserSource(userSource)
    }
  }, [userId, userSource, saveUserSource])

  return children
}

export default connect(
  (state) => ({
    userId: get(state, 'userReducer.user.id'),
    userSource: get(state, 'userReducer.utmSrc')
  }),
  {
    newMarketingSource: OrderActions.newMarketingSource,
    newUtmSource: MarketingActions.newUtmSource,
    saveUserSource: UserActions.saveUserSource,
  }
)(MarketingTracking)
