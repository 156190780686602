import { createActions } from 'redux-actions';
import ActionTypes from '../constants/ActionTypes';
import HttpClient from '../core/HttpClient';

const USER_SELF_PATH = '/api/users/self';
const SIGNUP_PATH = '/api/users';
const TOKEN_PATH = '/api/token';
const RESET_PASSWORD_PATH = '/api/password_resets';
const AUTHCODE_PATH = '/api/authcode';
const REGISTER_PATH = '/api/users/self/register';
const PHONE_CHANGE_PATH = '/api/users/self/phone_number';
const RECOVERY_PATH = '/api/users/recovery';

export default createActions(
  {
    [ActionTypes.USER_LOAD]: () => ({
      data: {},
      promise: HttpClient.get(USER_SELF_PATH)
    }),
    [ActionTypes.USER_UPDATE]: (data) => ({
      data: { data },
      promise: HttpClient.put(USER_SELF_PATH, data)
    }),
    [ActionTypes.USER_UPDATE_SEND_VERIFICATION]: () => ({
      data: {send_phone_number_token: true},
      promise: HttpClient.put(USER_SELF_PATH, {
        send_phone_number_token: true
      })
    }),
    [ActionTypes.USER_UPDATE_VALIDATE_PHONE]: (data) => ({
      data: { data },
      promise: HttpClient.put(USER_SELF_PATH, {
        phone_number_token_confirmation: data.replace('-', '')
      })
    }),
    [ActionTypes.USER_UPDATE_CHANGE_PHONE]: (phone_number) => {
      const data = { phone_number }
      return {
        data,
        promise: HttpClient.put(PHONE_CHANGE_PATH, data)
      }
    },
    [ActionTypes.USER_UPDATE_VERIFY_PHONE]: (phone_number, auth_code) => {
      const data = { phone_number, auth_code }
      return {
        data,
        promise: HttpClient.put(`${PHONE_CHANGE_PATH}/verify`, data)
      }
    },
    [ActionTypes.USER_REMOVE_CARD]: (card) => ({
      data: { card },
      promise: HttpClient.del(`/api/credit_cards/${card.ref_id}`)
    }),
    [ActionTypes.USER_RESET_PASSWORD]: (email) => ({
      data: { email },
      promise: HttpClient.post(RESET_PASSWORD_PATH, {email: email})
    }),
    [ActionTypes.USER_UPDATE_PASSWORD]: (token, password) => ({
      data: { token, password },
      promise: HttpClient.put(`${RESET_PASSWORD_PATH}/${token}`, {password: password})
    }),
    [ActionTypes.USER_AUTH]: (email, password) => ({
      data: { email, password },
      promise: HttpClient.post(TOKEN_PATH, { email, password })
    }),
    [ActionTypes.USER_SIGNUP]: (data) => ({
      data: { data },
      promise: HttpClient.post(SIGNUP_PATH, data)
    }),
    [ActionTypes.USER_PHONE_AUTH_CODE]: (phone_number) => {
      const data = { phone_number }
      return {
        data,
        promise: HttpClient.post(AUTHCODE_PATH, data)
      }
    },
    [ActionTypes.USER_PHONE_AUTH_LOGIN]: (phone_number, auth_code) => {
      const data = { phone_number, auth_code }
      return {
        data,
        promise: HttpClient.post(TOKEN_PATH, data)
      }
    },
    [ActionTypes.USER_MAGIC_LINK_AUTH_LOGIN]: (phone_number, token) => {
      const data = { phone_number, token }
      return {
        data,
        promise: HttpClient.post(TOKEN_PATH, data)
      }
    },
    [ActionTypes.USER_PHONE_AUTH_REGISTER]: (data) => {
      return {
        data,
        promise: HttpClient.post(REGISTER_PATH, data)
      }
    },
    [ActionTypes.USER_PHONE_AUTH_RECOVERY]: (data) => {
      return {
        data,
        promise: HttpClient.post(RECOVERY_PATH, data)
      }
    },
    [ActionTypes.USER_PHONE_AUTH_RECOVERY_VERIFY]: (id, email) => {
      const data = { email }
      return {
        data,
        promise: HttpClient.put(`${RECOVERY_PATH}/${id}`, data)
      }
    },
    [ActionTypes.SAVE_USER_SOURCE]: (source) => {
      const data = { source }
      return {
        data,
        promise: HttpClient.put(USER_SELF_PATH, data)
      }
    },
    [ActionTypes.USER_ADD_CARD]: (card) => ({
      data: { card },
      promise: HttpClient.post('/api/credit_cards/', card)
    }),
  },
  `${ActionTypes.USER_LOADED}`,
  `${ActionTypes.USER_LOGOUT}`,
  `${ActionTypes.SET_USER_PHONE_AUTH_RECOVERY_INFO}`,
);
