import ActionTypes from '../constants/ActionTypes';
import UIActionTypes from '../constants/UIActionTypes';
import UIActions from '../actionCreators/UIActions';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import cookieUtils from '../utils/cookieUtils';
import { formatErrors } from '../utils/errorUtils';
import ls from 'local-storage';
import OrderActions from '../actionCreators/OrderActions';
import PaymentUIActions from '../actionCreators/PaymentUIActions';
import { FUNDS_LOCK_KEY, MARKETING_SOURCE_KEY, ORDER_KEY, ORDER_UTM_SOURCE_KEY } from '../constants/sharedKeys';

const _order = ls.get(ORDER_KEY);
const _deliverypref = get(_order, "deliverypref");

const orderChannels = {}

const getSauceLineItems = (order) => {
  if (!order || order.state !== 'new') {
    return []
  }
  const lineItems = get(order, 'line_items', [])

  return groupBy(filter(lineItems, i => i.item_category === 'sauce'), i => `${i.item_id}_${i.label}`)
}

const getPrimaryLineItems = (order) => {
  if (!order || order.state !== 'new') {
    return []
  }
  const lineItems = get(order, 'line_items', [])

  return filter(lineItems, i => i.item_category !== 'sauce')
}

const initialState = {
  isConfirming: false,
  orders: {},
  order: (_order && _order.state === 'new') ? _order : {},
  primaryLineItems: getPrimaryLineItems(_order),
  sauceLineItems: getSauceLineItems(_order),
  deliverypref: _deliverypref ? _deliverypref : null,
  authPhoneNumber: null,
  phoneErrors: {},
  errors: null,
  loading: false,
  src: cookieUtils.getCookie(MARKETING_SOURCE_KEY),
  utmSrc: cookieUtils.getCookie(ORDER_UTM_SOURCE_KEY),
  itemCount: (_order && _order.state === 'new') ? _order.item_total : 0,
  isGroupOrder: get(_order, 'group_order', false),
  isSubOrder: get(_order, 'group_id', false)
};

const updateOrders = (order, orders, deliverypref) => {
  const updatedOrder = {
    ...orders[order.id],
    ...order,
    deliverypref
  };
  const orderNumber = order.number ? order.number : order.id;
  const itemCount = get(order, 'item_total', 0);
  if (order.state === 'new') {
    ls.set(ORDER_KEY, updatedOrder);
  } else {
    ls.remove(ORDER_KEY);
  }
  return {
    itemCount,
    order: updatedOrder,
    isGroupOrder: get(updatedOrder, 'group_order', false),
    isSubOrder: get(updatedOrder, 'group_id', false),
    primaryLineItems: getPrimaryLineItems(updatedOrder),
    sauceLineItems: getSauceLineItems(updatedOrder),
    orders: {
      ...orders,
      [order.id]: updatedOrder,
      [orderNumber]: updatedOrder
    }
  };
};

const clearOrder = (state, action) => {
  const orderId = get(state, 'order.id');
  if (!isEmpty(orderId)) {
    const channel = get(orderChannels, orderId);
    if (!isNil(channel)) {
      channel.leave()
      orderChannels[orderId] = null;
    }
  }
  action.sideEffect((store) => {
    store.dispatch(PaymentUIActions.removePaymentUiData());
  });

  ls.remove(ORDER_KEY)
  ls.remove(FUNDS_LOCK_KEY)
  cookieUtils.deleteCookie('promo_code')

  return {
    ...state,
    order: {},
    authPhoneNumber: null,
    phoneErrors: {},
    errors: null,
    itemCount: 0,
    deliverypref: null,
    isGroupOrder: false,
    isSubOrder: false,
    primaryLineItems: [],
    sauceLineItems: [],
  };
};

const startLoading = (state, action) => ({
  ...state, loading: true, phoneErrors: {}, errors: null
});

const orderSuccess = (state, action) => {
  const order = get(action, 'payload');
  const deliverypref = get(state, 'deliverypref');
  const updates = updateOrders(order, state.orders, deliverypref);
  const { src, utmSrc } = state;
  action.sideEffect((store) => {
    store.dispatch(PaymentUIActions.setPaymentUiData(order));

    if (src || utmSrc ) {
      store.dispatch(OrderActions.clearMarketingSource());
    }

    if (isNil(get(orderChannels, order.id))) {
      const socket = window.getWebSocket()
      const channel = socket.channel(`orders:${order.id}`)
      channel.join()
        .receive('ok', resp => { console.log('order channnel join OK', resp) })
        .receive('error', resp => { console.log('order channel join error', resp) })
      orderChannels[order.id] = channel

      channel.on('show_modal', resp => {
        store.dispatch(UIActions.showNotificationModal(get(resp, 'payload')))
      })
    }
  });
  return {
    ...state,
    ...updates,
    loading: false,
    errors: null,
    isGroupOrder: get(order, 'group_order', false),
    isSubOrder: get(order, 'group_id', false),
    primaryLineItems: getPrimaryLineItems(order),
    sauceLineItems: getSauceLineItems(order)
  }
};

const orderError = (state, action) => {
  const errorStatus = get(action, 'payload.response.status');
  const errors = formatErrors(get(action, 'payload.response.body'), {});
  const data = get(action, 'meta.data', {});

  action.sideEffect((store) => {
    if (errorStatus === 404) {
      clearOrder(state, action);
      store.dispatch(OrderActions.orderCreate({ ...data }));
    }
  });

  return { ...state, loading: false, errors }
};

const userAuthSuccess = (state, action) => {
  const userId = get(action, 'payload.user.id');
  const orderUserId = get(state, 'order.user.id');

  return (orderUserId && orderUserId !== userId) ?
    clearOrder(state, action) : { ...state };
}

export default createReducer(
  initialState,
  {
    [ActionTypes.ORDER_CREATE_REQUEST]: startLoading,
    [ActionTypes.ORDER_UPDATE_REQUEST]: startLoading,
    [ActionTypes.ORDER_LOAD_REQUEST]: startLoading,
    [ActionTypes.ORDER_CREATE_OR_UPDATE_REQUEST]: startLoading,
    [ActionTypes.ORDER_DELIVERY_LOCATION_UPDATE_REQUEST]: startLoading,

    [ActionTypes.ORDER_CREATE_SUCCESS]: orderSuccess,
    [ActionTypes.ORDER_UPDATE_SUCCESS]: orderSuccess,
    [ActionTypes.START_GROUP_CHECKOUT]: orderSuccess,
    [ActionTypes.ORDER_LOAD_SUCCESS]: orderSuccess,
    [ActionTypes.ORDER_CREATE_OR_UPDATE_SUCCESS]: orderSuccess,
    [ActionTypes.ORDER_DELIVERY_LOCATION_UPDATE_SUCCESS]: orderSuccess,

    [ActionTypes.ORDER_CREATE_ERROR]: orderError,
    [ActionTypes.ORDER_UPDATE_ERROR]: orderError,
    [ActionTypes.ORDER_CREATE_OR_UPDATE_ERROR]: orderError,
    [ActionTypes.ORDER_DELIVERY_LOCATION_UPDATE_ERROR]: orderError,

    [ActionTypes.ORDER_LOAD_ERROR]: (state, action) => ({ ...state, order: {} }),

    [ActionTypes.ORDER_CONFIRM_REQUEST]: (state, action) => (
      { ...state, isConfirming: true }
    ),
    [ActionTypes.ORDER_CONFIRM_ERROR]: (state, action) => (
      { ...orderError(state, action), isConfirming: false }
    ),
    [ActionTypes.ORDER_CONFIRM_SUCCESS]: (state, action) => (
      { ...clearOrder(state, action), isConfirming: false }
    ),

    [ActionTypes.ORDER_NOT_FOUND]: (state, action) => (clearOrder(state, action)),
    [ActionTypes.CLEAR_ORDER]: (state, action) => (clearOrder(state, action)),
    [ActionTypes.GROUPORDER_LEAVE]: (state, action) => {
      const { order } = state;
      action.sideEffect((store) => {
        const orderId = get(order, 'id');
        if (orderId) {
          store.dispatch(OrderActions.orderUpdate({ group_id: null }, orderId));
        }
      });
      const isGroupOrder = (order && order.group_order === true);
      return isGroupOrder ? clearOrder(state, action) : { ...state, errors: null };
    },

    [ActionTypes.ORDER_UPDATE_CHANGE_PHONE_REQUEST]: (state, action) => ({
      ...state,
      loading: true,
      authPhoneNumber: get(action, 'payload.phone_number'),
      phoneErrors: {},
      errors: null
    }),
    [ActionTypes.ORDER_UPDATE_CHANGE_PHONE_SUCCESS]: (state, action) => {
      action.sideEffect((store) => {
        store.dispatch(UIActions.showSigninSignupModal(false))
      });

      return orderSuccess(state, action)
    },
    [ActionTypes.ORDER_UPDATE_CHANGE_PHONE_ERROR]: (state, action) => {
      const phoneErrors = { ...get(action, 'payload.response.body.details', {}) }
      return { ...state, loading: false, phoneErrors }
    },

    [ActionTypes.NEW_MARKETING_SOURCE]: (state, action) => {
      const src = get(action, 'payload');
      cookieUtils.setCookie(MARKETING_SOURCE_KEY, src);
      return { ...state, src };
    },

    // Handle this MarketingAction but store the value separately. We need to keep and clear it independently
    // of the order
    [ActionTypes.NEW_UTM_SOURCE]: (state, action) => {
      const utmSrc = get(action, 'payload')
      cookieUtils.setCookie(ORDER_UTM_SOURCE_KEY, utmSrc);
      return { ...state, utmSrc };
    },

    [ActionTypes.CLEAR_MARKETING_SOURCE]: (state, action) => {
      cookieUtils.deleteCookie(MARKETING_SOURCE_KEY);
      cookieUtils.deleteCookie(ORDER_UTM_SOURCE_KEY);
      return { ...state, src: null, utmSrc: null };
    },
    [UIActionTypes.SET_DELIVERY_PREFERENCE]: (state, action) => {
      const deliverypref = action.payload;
      const updates = updateOrders(state.order, state.orders, deliverypref);
      return { ...state, ...updates, deliverypref };
    },
    [ActionTypes.USER_AUTH_SUCCESS]: userAuthSuccess,

    [ActionTypes.NEAREST_KITCHEN_SUCCESS]: (state, action) => {
      const kitchen_in_zone =  get(action, 'payload.in_zone');
      const location = get(action, 'meta.data.location');
      const address_components = get(action, 'meta.data.address');
      const address = get(action, 'meta.data.formatted_address');
      const pickup = get(action, 'meta.data.pickup');
      const orderId = get(state, 'order.id');
      const isConfirming = get(state, 'isConfirming', false);
      const isGroupOrder = get(state, 'order.group_order');
      if (!isConfirming && !isGroupOrder && kitchen_in_zone && orderId && !pickup) {
        action.sideEffect((store) => {
          store.dispatch(OrderActions.orderUpdate({
            delivery_address: {
              address,
              location,
              address_components
            }
          }, orderId));
        });
      }
      return  { ...state };
    },
  }
);
