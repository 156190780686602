import ActionTypes from '../constants/ActionTypes';
import UIActionTypes from '../constants/UIActionTypes';
import PaymentUIActions from '../actionCreators/PaymentUIActions';
import OrderActions from '../actionCreators/OrderActions';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import ls from 'local-storage';
import { USER_KEY, ORDER_KEY, FUNDS_LOCK_KEY } from '../constants/sharedKeys';
import cookieUtils from '../utils/cookieUtils';
import find from 'lodash/find';
import omitBy from 'lodash/omitBy';

const _fundslock = ls.get(FUNDS_LOCK_KEY)
const _user = ls.get(USER_KEY);
const _order = ls.get(ORDER_KEY);
const defaultPromo = cookieUtils.getCookie('promo_code');
const defaultPromoCode = defaultPromo ? encodeURIComponent(defaultPromo) : null;

const getPaymentCard = (order) => {
  const credit_card = get(order, 'credit_card', {});
  const token =get(order, 'payment_methods.token_id');
  return omitBy({ ...credit_card, token }, v => !v);
}

const getDefaultCard = (savedCards=[], paymentCard=null) => {
  if (!isEmpty(paymentCard)) {
    if (!paymentCard.expired) {
      return paymentCard
    }

    const foundCard = savedCards.find(card => (card.id === paymentCard.id))
    if (foundCard && !foundCard.expired) {
      return foundCard
    }
  }

  return savedCards.find(card => (!card.expired))
}

const getPromoValue = (order) => {
  const promoDiscount = find(get(order, 'price_lines', []), (line) => (line.name === 'Promo Discount'));
  const promoValue = get(promoDiscount, 'value', 0)
  return promoValue;
}

const orderLoading = (state) => ({...state, orderLoaded: false, });

const clearGiftCards = (state) => ({
  ...state,
  giftCardValue: null,
  giftCardDisplay: null,
  giftCardError: null
});


const referralCode = get(_user, 'referral_code', []);
const referralUrl = get(_user, 'referral_code_url', []);
const paymentCard =  getPaymentCard(_order);
const tipAmount = get(_order, 'tip', 0) / 100;
const tippingDisabled = (_order, 'tipping_disabled', null);
const appliedPromoCode = (_order, 'promo_code', null);
const promoValue = getPromoValue(_order);
const discountApplied = get(_order, 'points_discount');
const pointsApplied = get(_order, 'points_applied');
const fundsUsed = get(_order, 'funds_total', 0);
const creditCardTotal = get(_order, 'credit_card_total', 0);
const orderTotal = get(_order, 'total', 0);


export default createReducer(
  {
    referralCode,
    referralUrl,
    savedCards: [],
    tipAmount,
    appliedPromoCode,
    promoCode: null,
    cardError: null,
    paymentCard,
    defaultCard: null,
    tippingDisabled,
    defaultPromoCode,
    discountApplied,
    pointsApplied,
    promoValue,
    cardManagerMode: null,
    selectedCard: null,
    rememberCard: true,
    accountBalance: null,
    fundsUsed,
    creditCardTotal,
    fundsLock: _fundslock ? _fundslock : false,
    maxFundsAvailable: 0,
    orderTotal,
    accountBalanceLoaded: false,
    orderLoaded: true,
    paymentMethodError: null,
    giftCardValue: null,
    giftCardDisplay: null,
    giftCardError: null,
    redemptionCodeQueryParam: null
  },
  {
    [ActionTypes.ORDER_CREATE_REQUEST]: orderLoading,
    [ActionTypes.ORDER_UPDATE_REQUEST]: orderLoading,
    [ActionTypes.ORDER_LOAD_REQUEST]: orderLoading,
    [ActionTypes.ORDER_CREATE_OR_UPDATE_REQUEST]: orderLoading,
    [ActionTypes.ORDER_DELIVERY_LOCATION_UPDATE_REQUEST]: orderLoading,

    [ActionTypes.USER_ADD_CARD_ERROR]: (state, action) => {
      const paymentMethodError = get(action, 'payload.response.body.details')
      return { ...state, paymentMethodError }
    },

    [ActionTypes.USER_ADD_CARD_SUCCESS]: (state, action) => {
      action.sideEffect((store) => {
        store.dispatch(PaymentUIActions.accountBalanceLoad())
      })
      return { ...state, selectedCard: action.payload, paymentMethodError: null }
    },

    [ActionTypes.USER_REMOVE_CARD_SUCCESS]: (state, action) => {
      const card = get(action, 'payload');
      const cards = get(state, 'savedCards');
      const savedCards = filter(cards, crd => {
        return (crd.ref_id !== card.id)
      });

      const paymentCard = get(state, 'paymentCard', null);
      const defaultCard = getDefaultCard(savedCards, paymentCard);

      return { ...state, savedCards, defaultCard }
    },
    [ActionTypes.ACCOUNT_BALANCE_LOAD_REQUEST]: (state) =>
      ({...state, accountBalanceLoaded: false }),

    [ActionTypes.ACCOUNT_BALANCE_LOAD_SUCCESS]: (state, action) => {
      const accountBalance = get(action, 'payload', null);
      const savedCards = get(action, 'payload.credit_cards');
      const paymentCard = get(state, 'paymentCard', null);
      const defaultCard = getDefaultCard(savedCards, paymentCard);
      const orderTotal = get(state, 'orderTotal', 0);
      const balance = get(action, 'payload.balance_cents', 0);
      const maxFundsAvailable = Math.min(orderTotal, balance);
      const accountBalanceLoaded = true;

      return {
        ...state,
        accountBalance,
        savedCards,
        defaultCard,
        maxFundsAvailable,
        accountBalanceLoaded
      }
    },

    [ActionTypes.GIFT_CARD_REDEEM_SUCCESS]: (state, action) => {
      const giftCardValue = get(action, 'payload.amount_cents', 0);
      const giftCardDisplay = get(action, 'payload.display_amount', null);
      const accountBalance = get(action, 'payload.wallet', null)

      return {
        ...state,
        giftCardValue,
        giftCardDisplay,
        giftCardError: null,
        accountBalance
      }
    },

    [ActionTypes.GIFT_CARD_REDEEM_ERROR]: (state, action) => {
      const giftCardError = get(action, 'payload.response.body.description', null);

      return {
        ...state,
        giftCardError
      }
    },

    [UIActionTypes.CLEAR_GIFT_CARD]: clearGiftCards,
    '@@router/LOCATION_CHANGE': clearGiftCards,

    [UIActionTypes.UPDATE_TIP_AMOUNT]:
      (state, action) => {
        const group_id = get(action, 'payload.group_id');
        const order_id = get(action, 'payload.order_id');
        const tipAmount = get(action, 'payload.amount', 0);

        const prevTip = parseInt(get(state, 'tipAmount', 0) * 100, 10);
        const fundsLock = get(state, 'fundsLock', false);
        const fundsUsed = get(state, 'fundsUsed', 0);
        const accountBalance = get(state, 'accountBalance.balance_cents', 0);

        const tip = parseInt(tipAmount * 100, 10);
        const orderTotal = get(state, 'orderTotal', 0);
        const diffTip = tip - prevTip;
        const adjustedTotal = orderTotal + diffTip;
        const maxFundsAvailable = Math.min(adjustedTotal, accountBalance);
        const funds = (!fundsLock && fundsUsed < maxFundsAvailable) ?
          maxFundsAvailable : fundsUsed;

        action.sideEffect((store) => {
          store.dispatch(
            OrderActions.orderUpdate(
              {
                payment_methods: {
                  funds
                },
                group_id,
                tip
              },
              order_id
            )
          )
        })

        return { ...state, tipAmount }
    },
    [UIActionTypes.SET_PROMO_CODE]:
      (state, action) => ({ ...state, promoCode: action.payload }),
    [UIActionTypes.CLEAR_PROMO_CODE]: (state, action) => {
      cookieUtils.deleteCookie('promo_code')
      return { ...state, promoCode: null, defaultPromoCode: null}
    },
    [UIActionTypes.SET_CARD_ERROR]:
      (state, action) => ({ ...state, cardError: action.payload }),
    [UIActionTypes.SET_SELECTED_CARD]:
      (state, action) => ({ ...state, selectedCard: action.payload }),
    [UIActionTypes.SET_CARD_MANAGER_MODE]:
      (state, action) => ({ ...state, cardManagerMode: action.payload }),
    [UIActionTypes.SET_REMEMBER_CARD]:
      (state, action) => ({ ...state, rememberCard: action.payload }),
    [UIActionTypes.SET_UI_FUNDS_LOCK]: (state, action) => {
      action.payload ? ls.set(FUNDS_LOCK_KEY, true) : ls.remove(FUNDS_LOCK_KEY)
      return {
        ...state,
        fundsLock: action.payload
      }
    },

    [UIActionTypes.SET_PAYMENT_UI_DATA]: (state, action) => {
      const paymentCard = getPaymentCard(action.payload);
      const tippingDisabled = get(action, 'payload.tipping_disabled', null);
      const appliedPromoCode = get(action, 'payload.promo_code', null);
      const discountApplied = get(action, 'payload.points_discount', null);
      const pointsApplied = get(action, 'payload.points_applied', null);
      const tipAmount = get(action, 'payload.tip', 0) / 100;
      const savedCards = get(state, 'savedCards', []);
      const defaultCard = getDefaultCard(savedCards, paymentCard);
      const promoValue = getPromoValue(get(action, 'payload'));

      const fundsUsed = get(action, 'payload.funds_total', 0);
      const creditCardTotal = get(action, 'payload.credit_card_total', 0);
      const orderTotal = get(action, 'payload.total', 0);
      const accountBalance = get(state, 'accountBalance.balance_cents', 0);
      const maxFundsAvailable = Math.min(orderTotal, accountBalance);
      const orderLoaded = true;

      return {
        ...state,
        paymentCard,
        defaultCard,
        tippingDisabled,
        appliedPromoCode,
        discountApplied,
        pointsApplied,
        tipAmount,
        promoValue,
        creditCardTotal,
        fundsUsed,
        orderTotal,
        maxFundsAvailable,
        orderLoaded
      };
    },
    [UIActionTypes.REMOVE_PAYMENT_UI_DATA]: (state, action) => {
        action.sideEffect((store) => {
          store.dispatch(PaymentUIActions.accountBalanceLoad())
        })

        return {
        ...state,
        paymentCard: null,
        tippingDisabled: null,
        appliedPromoCode: null,
        defaultPromoCode: null,
        promoValue: 0,
        tipAmount: 0,
        creditCardTotal: 0,
        orderTotal: 0,
        fundsLock: false,
        maxFundsAvailable: 0,
        fundsUsed: 0,
        accountBalance: null,
        accountBalanceLoaded: false,
        orderLoaded: true
      };
    },
    [UIActionTypes.STORE_REDEMPTION_QUERY_PARAM]: (state, action) => {
      return {
        ...state,
        redemptionCodeQueryParam: action.payload
      }
    }
  }
);
