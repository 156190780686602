import { createActions } from 'redux-actions';
import ActionTypes from '../constants/ActionTypes';
import HttpClient from '../core/HttpClient';

const FAVORITES_PATH = '/api/users/self/favorites';

export default createActions(
  {
    [ActionTypes.FAVORITES_LOAD]: () => ({
      promise: HttpClient.get(FAVORITES_PATH)
    }),

    [ActionTypes.FAVORITE_LOAD]: (id) => ({
      promise: HttpClient.get(`${FAVORITES_PATH}/${id}`)
    }),

    [ActionTypes.FAVORITE_REMOVE]: [
      (id) => ({
        promise: HttpClient.del(`${FAVORITES_PATH}/${id}`)
      }),
      (data) => ({ data })
    ],

    [ActionTypes.FAVORITE_UPDATE]: [
      (id, data) => ({
        promise: HttpClient.put(`${FAVORITES_PATH}/${id}`,data)
      }),
      (id, data) => ({ id, data })
    ],

    [ActionTypes.FAVORITE_CREATE]: [
      (data) => ({ promise: HttpClient.post(FAVORITES_PATH, data) }),
      (data) => ({ data })
    ],

  },
  `${ActionTypes.CLEAR_FAVORITE}`,
);
