import { createActions } from 'redux-actions';
import ActionTypes from '../constants/ActionTypes';
import UIActionTypes from '../constants/UIActionTypes';
import HttpClient from '../core/HttpClient';
import cookieUtils from '../utils/cookieUtils';
import { MARKETING_SOURCE_KEY, KITCHEN_KEY, ORDER_UTM_SOURCE_KEY } from '../constants/sharedKeys';
import ls from 'local-storage';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const GROUP_ORDERS_PATH = '/api/users/self/groups';
const ORDER_PATH = '/api/orders';
const phoneChangeUrl = (id) => (`/api/orders/${id}/phone_number`)
const newOrderUrl = () => {
  const kitchenId = get(ls.get(KITCHEN_KEY), 'id');
  return `/api/kitchens/${kitchenId}/orders`;
};
const orderUrl = (id) => (id ? `${ORDER_PATH}/${id}` : newOrderUrl());
const confirmUrl = (id) => (`${orderUrl(id)}/confirm`);
const cancelUrl = (id) => (`${orderUrl(id)}/cancel`);
const orderIssueUrl = (id) => (`${ORDER_PATH}/${id}/issue`);
const suborderRemoveUrl = (id) => (`${ORDER_PATH}/${id}`);
const groupOrderUrl = () => (newOrderUrl());


const getMarketingSource = () => (
  {
    platform: 'web',
    version: window.BUILD_NUMBER,
    marketing_source: cookieUtils.getCookie(MARKETING_SOURCE_KEY),
    utm_source: cookieUtils.getCookie(ORDER_UTM_SOURCE_KEY),
  }
);

const orderUpdate = (dat, id) => {
    const source = getMarketingSource();
    const data = { ...dat, ...(!id ? source : {})};
    return {
      data: { data },
      promise: HttpClient.put(orderUrl(id), data)
    }
  };

const orderUpdateMap = [
  orderUpdate,
  (dat, id) => ({data: dat, id})
];

const orderCancel = (id) => ({
  promise: HttpClient.post(cancelUrl(id), {})
});

const orderCreate = (dat) => {
  const source = getMarketingSource();
  const data = { ...dat, source};
  return {
    data: { data },
    promise: HttpClient.post(newOrderUrl(), data)
  };
};

export default createActions(
  {
    [ActionTypes.ORDER_CREATE]: orderCreate,

    [ActionTypes.ORDER_CONFIRM]: (data, id) => ({
        data: { data },
        promise: HttpClient.post(confirmUrl(id), data)
      }),

    [ActionTypes.ORDER_LOAD]: (orderNumber) => ({
      data: { orderNumber },
      promise: HttpClient.get(orderUrl(orderNumber))
    }),

    [ActionTypes.ORDER_UPDATE]: orderUpdateMap,
    [ActionTypes.GROUPORDER_UPDATE]: orderUpdateMap,

    [ActionTypes.ORDER_CREATE_OR_UPDATE]: [
      (dat, id = null, createSubOrder = false, delivery_address = {}, pickup) => {
        const deliveryUpdates = !isEmpty(delivery_address) ? { delivery_address } : {}
        const createData = {
          ...dat,
          ...deliveryUpdates,
             pickup
        }

        return  (!id || createSubOrder) ?  orderCreate(createData) : orderUpdate(dat, id);
      },
      (dat, id = null, createSubOrder = false, delivery_address = {}, pickup) => {
        return {data: dat, id, createSubOrder, delivery_address, pickup}
      }
    ],

    [ActionTypes.ORDER_UPDATE_CHANGE_PHONE]: (id, phone_number) => ({
      data: { phone_number },
      promise: HttpClient.put(phoneChangeUrl(id), { phone_number })
    }),

    [ActionTypes.ORDER_CANCEL]: orderCancel,
    [ActionTypes.GROUPORDER_CANCEL]: orderCancel,

    [ActionTypes.ORDER_ISSUE]: (id, data) => ({
        data: { data },
        promise: HttpClient.post(orderIssueUrl(id), data)
      }),

    [ActionTypes.GROUPORDER_CREATE]: (dat) => {
      const source = getMarketingSource();
      const data = {...dat, source, group_order: true };
      return {
        data: { data },
        promise: HttpClient.post(groupOrderUrl(), data)
      };
    },

    [ActionTypes.GROUPORDERS_LOAD]: () => ({
      data: {},
      promise: HttpClient.get(GROUP_ORDERS_PATH)
    }),

    [ActionTypes.GROUPORDER_LOAD]: (id) => ({
      data: { id },
      promise: HttpClient.get(orderUrl(id))
    }),

    [ActionTypes.CURRENT_GROUP_UPDATE]: (id) => ({
      data: { id },
      promise: HttpClient.get(orderUrl(id))
    }),

    [ActionTypes.SUBORDER_REMOVE]: (id) => ({
      data: { id },
      promise: HttpClient.del(suborderRemoveUrl(id))
    }),

    [ActionTypes.ORDER_DELIVERY_LOCATION_UPDATE]:
      (id, delivery_address, dropoff_location_id = null, pickup) => {
        const payload = {
          delivery_address,
          pickup,
          ...(!isNil(dropoff_location_id) ? { dropoff_location_id }  : {}),
        }
        return {
          data: { id, delivery_address, dropoff_location_id, pickup },
          promise: HttpClient.put(`${ORDER_PATH}/${id}/delivery_location`, payload)
        };
      }

  },
  `${ActionTypes.NEW_MARKETING_SOURCE}`,
  `${ActionTypes.CLEAR_MARKETING_SOURCE}`,
  `${ActionTypes.GROUPORDER_JOIN}`,
  `${ActionTypes.START_GROUP_CHECKOUT}`,
  `${ActionTypes.GROUPORDER_LEAVE}`,
  `${ActionTypes.CLEAR_ORDER}`,
  `${UIActionTypes.SET_DELIVERY_PREFERENCE}`,
  `${UIActionTypes.LISTEN_TO_CURRENT_GROUP_CHANNEL}`,
  `${UIActionTypes.LEAVE_CURRENT_GROUP_CHANNEL}`,
  `${UIActionTypes.CURRENT_GROUP_UPDATE_EVENT}`,
  `${UIActionTypes.REMOVE_FROM_USER_LEFT_GROUP}`,
);
