import isString from 'lodash/isString';

export function isAuthenticated(token) {
  if (isString(token)) {
    let bits = token.split('.');
    if (bits.length !== 3) {
      return false;
    }
    try {
      let jwtData = atob(bits[1]);
      jwtData = JSON.parse(jwtData);
      let now = (new Date().getTime())/1000;
      if (now >= jwtData.exp) {
        return false;
      }
    } catch(e) { }

    return true;
  }
  return false;
};
