import { TREATMENTS_KEY } from '../constants/sharedKeys';
import UIActionTypes from  '../constants/UIActionTypes'
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import ls from 'local-storage';

const initialState = {
  anonymousId: null,
  ready: false,
  treatments: {},
  overrides: {}
};

const storeTreatments = (treatments) => { ls.set(TREATMENTS_KEY, treatments) }

export default createReducer(
  initialState,
  {
    [UIActionTypes.SPLIT_READY]: (state) => ({ ...state, ready: true }),
    [UIActionTypes.SET_TREATMENTS]: (state, action) => {
      const treatments = { ...get(action, 'payload'), ...state.overrides };

      storeTreatments(treatments);
      return { ...state, treatments };
    },
    [UIActionTypes.SET_TREATMENT_OVERRIDE]: (state, action) => {
      const treatments = { ...state.treatments, ...get(action, 'payload') };
      const overrides = { ...state.overrides, ...get(action, 'payload') };

      storeTreatments(treatments);
      return { ...state, treatments, overrides };
    },
    [UIActionTypes.SET_ANONYMOUS_ID]: (state, action) => {
      const anonymousId = get(action, 'payload');
      return { ...state, anonymousId };
    }
  }
);
