import get from 'lodash/get';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';
import values from 'lodash/values';

export function formatErrors(error) {
  const details = get(error, 'details');
  const description = get(error, 'description', 'Oh No! there has been an error.');
  return isObject(details) ?
  { fields: keys(details), message: values(details).join(', '), details } :
  { fields: [], message: description, details };
};
