import { createActions } from 'redux-actions';
import ActionTypes from '../constants/ActionTypes';
import HttpClient from '../core/HttpClient';
import { V2_ACCEPT_HEADER, V3_ACCEPT_HEADER } from '../core/HttpClient';
const SAVED_ADDRESS_PATH = '/api/users/self/saved_delivery_addresses';

const locationChangeAPI = [
  (location, formatted_address, address, type) => ({
    data: { location, formatted_address, address, type },
    promise: HttpClient.get('/api/kitchens', {}, {q: JSON.stringify(location)}, V2_ACCEPT_HEADER)
  }),
  (location, formatted_address, address, type) => ({ data: { location, formatted_address, address, type } })
]

export default createActions(
  {
    [ActionTypes.LOCATION_CHANGE]: locationChangeAPI,
    [ActionTypes.VALIDATE_LOCATION]: locationChangeAPI,

    [ActionTypes.KITCHENS_LOAD]: () => ({
      promise: HttpClient.get('/api/kitchens', {}, null, V2_ACCEPT_HEADER)
    }),

    [ActionTypes.NEAREST_KITCHEN]: [
      (
        location,
        formatted_address,
        address,
        buffer=40234,
        type,
        reset = true,
        pickup = false,
      ) => ({
        data: { location, formatted_address, address, buffer, type, reset },
        promise: HttpClient.get('/api/kitchens', {}, { ...location, buffer }, V3_ACCEPT_HEADER)
      }),
      (
        location,
        formatted_address,
        address,
        buffer=40234,
        type,
        reset = true,
        pickup = false,
        ) => ({ data: { location, formatted_address, address, buffer, type, reset, pickup } })
    ],

    [ActionTypes.SAVED_ADDRESSES_LOAD]: () => ({
      promise: HttpClient.get(SAVED_ADDRESS_PATH)
    }),

    [ActionTypes.SAVED_ADDRESS_CREATE]: [
      (address_type, delivery_address) => ({
        promise: HttpClient.post(
          SAVED_ADDRESS_PATH,
          { address_type, delivery_address }
        )
      }),
      (address_type, delivery_address ) => ({ data: { address_type, delivery_address  } })
    ],

    [ActionTypes.SAVED_ADDRESS_REMOVE]: [
      (id) => ({
        promise: HttpClient.del(`${SAVED_ADDRESS_PATH}/${id}`)
      }),
      (id) => ({ data: { id } })
    ],
    [ActionTypes.PICKUP_LOCATIONS_LOAD]: (lat, lng) => ({
      promise: HttpClient.get('/api/pickup_locations', {}, {lat, lng})
    }),
  },
  `${ActionTypes.SET_DELIVERY_ADDRESS}`,
  `${ActionTypes.STORE_LOCATION_SUBDOMAIN}`,
  `${ActionTypes.SET_PICKUP}`,
);
