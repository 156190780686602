import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

const subscriptionSuccess = (state, action) =>
  ({
    ...state,
    status: action.payload,
    loading: false,
    error: get(action, 'payload.payment_error')
  });

const subscriptionError = (state, action) => {
  if (get(action, 'payload.status') === 404) {
    return {
      ...state,
      loading: false,
      status: null
    }
  }

  const details = get(action, 'payload.response.body.details');

  return {
    ...state,
    error: isArray(details) ? details[0] : details,
    loading: false,
  }
}

const subscriptionRequest = (state) => ({ ...state, error: null, loading: true });

const subscriptionCancel = (state, action) => ({
  ...state,
  status: null,
  loading: false,
  error: null
});

export default createReducer(
  {
    subscription: null,
    error: null,
    loading: false,
  },
  {

    [ActionTypes.SUBSCRIPTION_STATUS_REQUEST]: subscriptionRequest,
    [ActionTypes.SUBSCRIPTION_STATUS_SUCCESS]: subscriptionSuccess,
    [ActionTypes.SUBSCRIPTION_STATUS_ERROR]: subscriptionError,

    [ActionTypes.SUBSCRIPTION_UPDATE_REQUEST]: subscriptionRequest,
    [ActionTypes.SUBSCRIPTION_UPDATE_SUCCESS]: subscriptionSuccess,
    [ActionTypes.SUBSCRIPTION_UPDATE_ERROR]: subscriptionError,

    [ActionTypes.SUBSCRIPTION_CANCEL_REQUEST]: subscriptionRequest,
    [ActionTypes.SUBSCRIPTION_CANCEL_SUCCESS]: subscriptionCancel,
    [ActionTypes.SUBSCRIPTION_CANCEL_ERROR]: subscriptionError
  }
);
