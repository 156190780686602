import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import slice from 'lodash/slice';
import { findIndex } from 'lodash'
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import ls from 'local-storage';

import { MENU_COMPONENTS_KEY, RECENT_SEARCHES_KEY } from '../constants/sharedKeys';
import ActionTypes from '../constants/ActionTypes';
import createReducer from '../core/createReducer';
import UIActions from '../actionCreators/UIActions';
import analyticsManifest from '../analytics/analyticsManifest';

const IMPLEMENTED_COMPONENTS = [
  'TruckMenuComponent',
  'BannerMenuComponent',
  'SourceBannerMenuComponent',
  'ClusterpointsMenuComponent',
  'SlackMenuComponent'
];

const MAX_RECENT_SEARCHES = 5;

const initialState = {
  list: [],
  trucksBySlug: {},
  tagIndex: {},
  items: [],
  itemOrderMap: {},
  filtered: false,
  noResults: false,
  filter: {},
  searchResults: null,
  searchLoading: false,
  recentSearches: [],
  lastFilter: null,
  error: null,
  loading: false
};

const clearSearch = () => ({
  filter: {},
  filtered: false,
  searchResults: null,
  lastFilter: null,
})


const onMenuLoadSuccess = (state, action) => {
  const components = get(action, 'payload.components', []);

  const componentsToBeShown = components
    .filter(component => {
      switch (component.type) {
        case 'TruckMenuComponent':
          const truck = component.data;
          return !isEmpty(truck.items);

        default:
          return IMPLEMENTED_COMPONENTS.includes(component.type);
      }
    });

  action.sideEffect(store => {
    const pluckedComponents = components.reduce((sum, component) => sum.concat(component.id), []);
    const pluckedComponentsToBeShown = componentsToBeShown.reduce((sum, component) => sum.concat(component.id), []);
    const componentsNotToBeShown = difference(pluckedComponents, pluckedComponentsToBeShown);

    const menuComponentsLoaded = get(analyticsManifest, 'customEvents.menuComponentsLoaded');
    store.dispatch(
      UIActions.enhancedTrack(menuComponentsLoaded(pluckedComponentsToBeShown, componentsNotToBeShown))
    );
  });

  ls.set(MENU_COMPONENTS_KEY, {list: componentsToBeShown});

  return {
    ...state,
    list: componentsToBeShown,
    loading: false
  };
};

const removeMenuComponentItem = (component, itemUpdate) => {
  const { data } = component
  const { items } = data
  const idx = findIndex(items, (item) => (item.id === itemUpdate.id))
  if (idx === -1) {
    return component
  }

  return {
    ...component,
    data: {
      ...data,
      items: [
        ...items.splice(0, idx),
        ...items.splice(idx)
      ]
    }
  }
}

const updateMenuItem = (items, itemUpdate) => {
  return items.map(item => {
    if (item.id === itemUpdate.id) {
      return {
        ...item,
        ...itemUpdate
      }
    }
    return item
  })
}

const updateMenuComponentItem = (component, itemUpdate) => {
  const { data } = component
  const { items } = data
  const truckId = data.id
  const truckIds = itemUpdate.trucks.map(t => t.id)

  if (truckIds.indexOf(truckId) > -1) {
    const idx = findIndex(items, (item) => (item.id === itemUpdate.id))
    // insert item at end of list
    if (idx === -1) {
      return {
        ...component,
        data: {
          ...data,
          items: [...items, itemUpdate]
        }
      }
    }

    // update item in list
    return {
      ...component,
      data: {
        ...data,
        items: updateMenuItem(items, itemUpdate)
      }
    }
  }

  return component
}

const updateItemInMenuComponents = (components, item) => {
  const removeItem = !item.published || item.archived
  const updateFunc = removeItem ? removeMenuComponentItem : updateMenuComponentItem

  return components.map(component => {
    const { type } = component
    if (type === 'TruckMenuComponent') {
      return updateFunc(component, item)
    }
    return component
  })
}

const appendRecentSearch = str => {
  const existing = ls.get(RECENT_SEARCHES_KEY) || [];
  const newSet = slice([str, ...without(existing, str)], 0, MAX_RECENT_SEARCHES);
  ls.set(RECENT_SEARCHES_KEY, newSet);
  return newSet;
}

export default createReducer(
  { ...initialState, ...ls.get(MENU_COMPONENTS_KEY), recentSearches: ls.get(RECENT_SEARCHES_KEY) || [] },
  {
    [ActionTypes.MENU_LOAD_REQUEST]: (state, action) => ({ ...state, loading: true }),
    [ActionTypes.MENU_LOAD_SUCCESS]: onMenuLoadSuccess,
    [ActionTypes.MENU_LOAD_ERROR]: (state, action) => {
      const error = get(action, 'payload.response.body');

      return { ...state, loading: false, error };
    },
    [ActionTypes.MENU_EXECUTE_SEARCH]: (state, action) => {
      if (get(action, 'payload.emptySearch', false)) {
        return {
          ...state,
          ...clearSearch()
        }
      }

      return state;
    },
    [ActionTypes.MENU_EXECUTE_SEARCH_REQUEST]: (state, action) => {
      const filterTags = get(state, 'filter.tags', []);
      if (!isEmpty(filterTags) && !isEqual(filterTags, get(state, 'lastFilter.tags', []))) {
        action.sideEffect(store => {
          const productListFiltered = get(analyticsManifest, 'customEvents.productListFiltered');
          store.dispatch(
            UIActions.enhancedTrack(productListFiltered(filterTags.map(t => ({type: t, value: true}))))
          );
        });
      }

      const filterText = get(state, 'filter.text', '');
      if (!isEmpty(filterText) && !isEqual(filterText, get(state, 'lastFilter.text', ''))) {
        action.sideEffect(store => {
          const productsSearched = get(analyticsManifest, 'customEvents.productsSearched');
          store.dispatch(
            UIActions.enhancedTrack(productsSearched(filterText))
          );
        });
      }

      return {
        ...state,
        searchLoading: true,
        searchResults: null
      };
    },
    [ActionTypes.MENU_EXECUTE_SEARCH_SUCCESS]: (state, action) => {
      const filterText = get(state, 'filter.text', '');
      const recentSearches = !isEmpty(filterText) ? appendRecentSearch(filterText) : state.recentSearches;

      return {
        ...state,
        searchLoading: false,
        filtered: true,
        searchResults: get(action, 'payload.results'),
        recentSearches,
        lastFilter: {
          ...state.filter
        },
      }
    },
    [ActionTypes.MENU_UPDATE_SEARCH]: (state, action) => ({
      ...state,
      filter: get(action, 'payload', {}),
    }),
    [ActionTypes.MENU_CLEAR_SEARCH]: (state, action) => ({
      ...state,
      ...clearSearch()
    }),
    [ActionTypes.ITEM_LIVE_UPDATE]: (state, {payload: item}) => ({
      ...state,
      list: [
        ...updateItemInMenuComponents(state.list, item)
      ]
    })
  }
);
