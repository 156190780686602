import { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import Split from './Split'
import { ReactReduxContext } from 'react-redux';

const TRAIT_PROPERTIES = ['platform', 'version', 'environment', 'build_type', 'kitchen_id', 'authenticated']
const buildTraits = (props) => (pick(props, TRAIT_PROPERTIES))

class SplitSupport extends Component {
  static contextType = ReactReduxContext;

  componentDidUpdate(prevProps) {
    const {
      anonymousId,
      userId,
    } = this.props

    if (!anonymousId) {
      return
    }
    const traits = buildTraits(this.props)
    const prevTraits = buildTraits(prevProps)
    const store =  this.context.store;

    // Restart the split client if our user identification or any traits changed
    if (anonymousId !== prevProps.anonymousId ||
        userId !== prevProps.userId ||
        !isEqual(prevTraits, traits)) {
      Split.start({store, userId, anonymousId, traits})
    }
  }

  render() {
    return this.props.children
  }
}

export default connect(
  (state) => ({
    anonymousId: get(state, 'treatmentReducer.anonymousId'),
    userId: get(state, 'userReducer.user.id'),
    platform: get(window, 'ENV.PLATFORM'),
    version: get(window, 'ENV.BUILD_NUMBER'),
    environment: get(window, 'ENV.CT_ENV'),
    build_type: get(window, 'ENV.BUILD_TYPE'),
    kitchen_id: get(state, 'locationReducer.kitchen.id'),
    authenticated: get(state, 'userReducer.isAuthenticated')
  })
)(SplitSupport)
