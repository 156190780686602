import { createActions } from 'redux-actions';
import ActionTypes from '../constants/ActionTypes';
import HttpClient from '../core/HttpClient';

export default createActions({
  [ActionTypes.ITEM_LOAD]: [
    (slug) => ({
      promise: HttpClient.get(`/api/items/${slug}`)
    }),
    (slug) => ({ data: slug })
  ],
  [ActionTypes.ITEM_LIVE_UPDATE]: ({item}) => item,
  [ActionTypes.SET_ITEM_MODIFIER_ERRORS]: errors => errors
});
